import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DigitalProduct, GetDigitalProductRequest, GetDigitalProductResponse, GetListRequest, GetListResponse, UpdateDigitalProductRequest } from "_interfaces/digitalProduct.interface";
import { ApiErrorResponse, ApiSuccessResponse } from "_network/response";
import { DigitalProductApi } from "_services/digitalProduct.services";
import { hideLoading, showAlert, showLoading } from "../../redux/global/global.action";
import { useAppDispatch } from "../../redux/hook";

const useDigitalProduct = (): {
  GetDigitalProduct: (params: GetDigitalProductRequest) => void;
  listDigitalProduct: GetDigitalProductResponse | undefined;
  UpdateDigitalProduct: (payload: UpdateDigitalProductRequest) => Promise<void>;
  GetList: (params: GetListRequest) => void;
  listTransactions: GetListResponse | undefined;
  FindDigitalProduct: (code: string) => void;
  digitalProductDetail: DigitalProduct | undefined;
} => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [listDigitalProduct, setListDigitalProduct] = useState<GetDigitalProductResponse>();
  const [listTransactions, setListTransactions] = useState<GetListResponse>();
  const [digitalProductDetail, setDigitalProductDetail] = useState<DigitalProduct>();

  const GetDigitalProduct = async (params: GetDigitalProductRequest) => {
    try {
      dispatch(showLoading());
      const res = await DigitalProductApi.GetDigitalProduct(params);
      setListDigitalProduct(res.data)
    } catch (error) {
      dispatch(showAlert("Failed to get data", "error"));
      dispatch(hideLoading());
    } finally {
      dispatch(hideLoading())
    }
  };

  const UpdateDigitalProduct = async (payload: UpdateDigitalProductRequest) => {
    try {
      dispatch(showLoading());
      const res = await DigitalProductApi.UpdateDigitalProduct(payload);
      toast.success("Update data success")
    } catch (error) {
      dispatch(showAlert("Failed to get data", "error"));
      dispatch(hideLoading());
      toast.error((error as ApiErrorResponse).message)
    } finally {
      dispatch(hideLoading())
    }
  }

  const GetList = async (params: GetListRequest) => {
    try {
      dispatch(showLoading());
      const res = await DigitalProductApi.GetList(params);
      setListTransactions(res.data)
    } catch (error) {
      dispatch(showAlert("Failed to get data", "error"));
      dispatch(hideLoading());
    } finally {
      dispatch(hideLoading())
    }
  };

  const FindDigitalProduct = async (code: string) => {
    try {
      dispatch(showLoading());
      const res = await DigitalProductApi.FindDigitalProduct(code);
      setDigitalProductDetail(res.data)
    } catch (error) {
      dispatch(showAlert("Failed to get data", "error"));
      dispatch(hideLoading());
    } finally {
      dispatch(hideLoading())
    }
  }

  return {
    GetDigitalProduct,
    listDigitalProduct,
    UpdateDigitalProduct,
    GetList,
    listTransactions,
    FindDigitalProduct,
    digitalProductDetail,
  };
};

export default useDigitalProduct;
