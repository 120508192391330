import { ThemeProps } from 'flowbite-react';

export const flowbite_theme: ThemeProps = {
  theme: {
    button: {
      color: {
        info: 'text-white bg-primary border border-transparent hover:bg-primary-200 focus:ring-4 focus:ring-blue-700 disabled:hover:bg-primary-300 dark:bg-primary-400 dark:hover:bg-primary-300 dark:focus:ring-primary-200 dark:disabled:hover:bg-primary-400',
      },
      base: 'focus:!ring-2 group h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 rounded-lg',
    },
    modal: {
      base: 'fixed top-0 right-0 left-0 z-50 h-modal overflow-y-auto overflow-x-hidden md:inset-0 md:h-full modal-dialog-centered',
      show: {
        on: 'flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80 modal-dialog-centered',
        off: 'hidden',
      },
      content: {
        base: 'relative h-full w-full p-4',
      },
    },
    badge: {
      base: 'flex w-fit h-fit items-center gap-1 font-semibold',
      color: {
        info: 'bg-primary-100 text-primary-800 dark:bg-primary-200 dark:text-primary-800 group-hover:bg-primary-200 dark:group-hover:bg-primary-300',
      },
    },
  },
};
