import DataTable from "../../components/data-tables";
import { FiEdit, FiDelete, FiFilter } from "react-icons/fi";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../redux/hook";
import { hideLoading, setHeader, showLoading } from "../../redux/global/global.action";
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import Filter from "../../components/filter";
import { Badge, Button, Pagination, Table } from "flowbite-react";
import { SuperadminServices } from "../../_services/superadmin.services";
import { WageRequestDetails } from "../../_interfaces";
import { ApiErrorResponse, UnknownError } from "../../_network/response";
import moment from "moment";
import { toast } from 'react-toastify';


const Transaction = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setHeader({ title: "EWA Transaction" }));
    dispatch(hideLoading())
  }, []);
  
  var sd = new Date();
  sd.setDate(sd.getDate()-7);

  const [param, setParam] = useState({startDate: sd, endDate: new Date(), currentPage: 1})
  const [datePickerStartDate, setDatePickerStartDate] = useState(sd)
  const [datePickerEndDate, setDatePickerEndDate] = useState(new Date())


  const [result, setResult] = useState<WageRequestDetails>();
  const [error, setError] = useState<ApiErrorResponse>();

  useEffect(() => {
    async function fetch(_startDate :Date, _endDate :Date, _currentPage :number) {
      try {
        dispatch(showLoading())
        var res = await SuperadminServices.getWageRequestByDateRange(_startDate, _endDate, _currentPage, 50);
        setResult(res.data);
      } catch (error) {
        if(error as ApiErrorResponse){
          setError(error as ApiErrorResponse);
          toast.error((error as ApiErrorResponse).message)
        }  else {
          console.log("Unknown error:",error);
          setError(UnknownError(""));
          toast.error("Internal Error")
        }
      } finally {
        dispatch(hideLoading())
      }
    }

    fetch(param.startDate, param.endDate, param.currentPage)
  }, [param]);
  
  
  return (
    <>
      <div className="w-full p-5 rounded-xl shadow bg-white mb-10">
        <div className="flex flex-col gap-5">
          <h1 className="text-md self-start inline-flex items-center gap-2">
            <FiFilter /> Filter
          </h1>
        </div>
        <div className="mt-5">
          <h2 className="text-base ">Pilih Tanggal</h2>
          <div>
            <DatePicker
                selected={datePickerStartDate}
                onChange={(date :Date) => setDatePickerStartDate(date)}
                selectsStart
                nextMonthButtonLabel=">"
                previousMonthButtonLabel="<"
                dateFormat="dd-MM-yyyy"
                className="block w-full text-base md:text-sm bg-white border border-gray-300 rounded shadow-sm form-input"
            />
            
            <FontAwesomeIcon className="ml-1 mr-1" icon={faMinus}></FontAwesomeIcon>
            <DatePicker
                selected={datePickerEndDate}
                onChange={(date :Date) => setDatePickerEndDate(date)}
                selectsEnd
                nextMonthButtonLabel=">"
                previousMonthButtonLabel="<"
                dateFormat="dd-MM-yyyy"
                className="block w-full text-base md:text-sm bg-white border border-gray-300 rounded shadow-sm form-input"
            />
            </div>
        </div>
        <div className="self-center flex flex-row gap-5 mt-5">
          <Button onClick={()=>{
            setParam({...param, currentPage: 1, startDate: datePickerStartDate, endDate: datePickerEndDate})
          }}>Apply</Button>
          <Button color="gray" onClick={()=>{
            setDatePickerStartDate(sd)
            setDatePickerEndDate(new Date())
            setParam({...param, currentPage: 1, startDate: sd, endDate: new Date()})
          }}>Reset</Button>
        </div>
      </div>
      
      {/* https://flowbite.com/docs/components/tables/ */}
      <Table striped={true}>
        <Table.Head>
          <Table.HeadCell> Tanggal </Table.HeadCell>
          <Table.HeadCell> Company </Table.HeadCell>
          <Table.HeadCell> NIP </Table.HeadCell>
          <Table.HeadCell> Nama </Table.HeadCell>
          <Table.HeadCell> Phone Number </Table.HeadCell>
          <Table.HeadCell> Amount </Table.HeadCell>
          <Table.HeadCell> Fee </Table.HeadCell>
          <Table.HeadCell> Principal </Table.HeadCell>
          <Table.HeadCell> Deskripsi </Table.HeadCell>
          <Table.HeadCell> Status </Table.HeadCell>
          <Table.HeadCell> Disbursed At </Table.HeadCell>
          <Table.HeadCell> Receipt </Table.HeadCell>
          {/* <Table.HeadCell> Action </Table.HeadCell> */}
        </Table.Head>
        <Table.Body className="divide-y">
          {
            result?.wageRequests.map( (wr) => {
              return <Table.Row key={"wage-request-"+wr.id.toString()} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {moment.tz(wr.requestedAt, 'Asia/Jakarta').format("DD-MM-YYYY HH:mm")}
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap"> {wr.companyName} </Table.Cell>
                <Table.Cell className="whitespace-nowrap"> {wr.nip} </Table.Cell>
                <Table.Cell className="whitespace-nowrap"> {wr.name} </Table.Cell>
                <Table.Cell className="whitespace-nowrap"> {wr.phoneNumber} </Table.Cell>
                <Table.Cell className="whitespace-nowrap"> {wr.amount.toLocaleString("id-ID", {style: "currency", currency: "IDR", minimumFractionDigits: 0}) } </Table.Cell>
                <Table.Cell className="whitespace-nowrap"> {wr.fee.toLocaleString("id-ID", {style: "currency", currency: "IDR", minimumFractionDigits: 0})} </Table.Cell>
                <Table.Cell className="whitespace-nowrap"> {wr.principal.toLocaleString("id-ID", {style: "currency", currency: "IDR", minimumFractionDigits: 0})} </Table.Cell>
                <Table.Cell> {wr.description} </Table.Cell>
                <Table.Cell className="whitespace-nowrap"> <Badge color={wr.status == 'SUCCESS' ? 'success' : wr.status == 'PROCESSING' ? 'warning':'failure'}>{wr.status}</Badge> </Table.Cell>
                <Table.Cell className="whitespace-nowrap"> {wr.disbursedAt ? moment.tz(wr.disbursedAt, 'Asia/Jakarta').format("DD-MM-YYYY HH:mm") : ''} </Table.Cell>
                <Table.Cell className="whitespace-nowrap"> {wr.receiptLink != "" ? <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href={wr.receiptLink} target="_blank">Link</a> : "" } </Table.Cell>
                
              </Table.Row>
            })
          }
        </Table.Body>
      </Table>
      <Pagination
        currentPage={param.currentPage}
        totalPages={result ? result.pagination.lastPage : param.currentPage}
        onPageChange={ (page:number)=> {
          setParam({...param, currentPage: page})
        }}
      />
    </>
  );
};

export default Transaction;
