import { useState } from "react";

import { toast } from 'react-toastify';
import { hideLoading, showLoading } from "redux/global/global.action";
import { useAppDispatch } from "redux/hook";
import { ApiErrorResponse } from "_network/response";
import { uploadServices } from "_services/upload.services";

const useUpload = (): [
  string,
  (image: any, location: string, fromBase64?: boolean) => void,
] => {
  const dispatch = useAppDispatch();
  const [fileUrl, setFileUrl] = useState("");

  const upload = async (image: any, location: string) => {
    try {
      dispatch(showLoading())
      const action = await uploadServices.uploadImage(image, location);
      setFileUrl(action.data.url);
      toast.success("Upload Sukses")
      dispatch(hideLoading());
    } catch (error) {
      dispatch(hideLoading())
      if(error as ApiErrorResponse){
        toast.error((error as ApiErrorResponse).message)
      }  else {
        toast.error("Internal Error")
      }
    }
  };

  return [fileUrl, upload];
};

export default useUpload;
  
