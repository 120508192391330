import GlobalActionTypes from "./global.types";

export const setHeader = ({ title = "", hasBack = false }) => {
  return (dispatch: any) =>
    dispatch({
      type: GlobalActionTypes.SET_HEADER,
      payload: { title, hasBack },
    });
};

export const hideSuccess = () => async (dispatch: any) => {
  dispatch({ type: GlobalActionTypes.HIDE_SUCCESS });
};

export const showLoading = () => async (dispatch: any) => {
  dispatch({ type: GlobalActionTypes.SHOW_LOADING });
};

export const hideLoading = () => async (dispatch: any) => {
  dispatch({ type: GlobalActionTypes.HIDE_LOADING });
};

export const showAlert =
  (alertMessage: string, alertType: string) => async (dispatch: any) => {
    dispatch({
      type: GlobalActionTypes.SHOW_ALERT,
      payload: { alertMessage, alertType },
    });
  };

export const hideAlert = () => async (dispatch: any) => {
  dispatch({ type: GlobalActionTypes.HIDE_ALERT });
};
