import moment from "moment";
import {  HolidayTemplates, HolidayType } from "../_interfaces/holiday-api.interfaces";
import request from "../_network/request";
import { ApiSuccessResponse } from "../_network/response";

const HolidayTemplate_Create = (dateAt: Date, name: string, holidayType: HolidayType) :Promise<ApiSuccessResponse<void>> => {
  return request({
    url: `holiday/template`,
    method: "POST",
    data: {
      "dateAt": moment(dateAt).format("YYYY-MM-DD"),
      "name": name,
      "holidayType": holidayType.toString()
    },
  });
};

const HolidayTemplate_Update = (id:number, name: string, holidayType: HolidayType) :Promise<ApiSuccessResponse<void>> => {
  return request({
    url: `holiday/template/`+id.toString(),
    method: "PUT",
    data: {
      name,
      holidayType
    },
  });
};

const HolidayTemplate_Delete = (id:number) :Promise<ApiSuccessResponse<void>> => {
  return request({
    url: `holiday/template/`+id.toString(),
    method: "DELETE",
  });
};

const HolidayTemplate_GetByYear = (year:number) :Promise<ApiSuccessResponse<HolidayTemplates>> => {
  return request({
    url: `holiday/template?year=`+year.toString(),
    method: "GET",
  });
};


export const HolidayApiServices = {
  HolidayTemplate_Create,
  HolidayTemplate_Update,
  HolidayTemplate_Delete,
  HolidayTemplate_GetByYear
};
