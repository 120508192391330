import { useEffect, useState } from 'react';
import { ApiErrorResponse, UnknownError } from '../../_network/response';
import { SuperadminServices } from '../../_services';

export function useGetFlipBalance(): [number, ApiErrorResponse | undefined, boolean] {
  const [result, setResult] = useState(0);
  const [error, setError] = useState<ApiErrorResponse>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetch() {
      try {
        setResult(0);
        setError(undefined);
        setLoading(true);

        var res = await SuperadminServices.getFlipBalance();
        setResult(res.data.amount);
      } catch (error) {
        if (error as ApiErrorResponse) {
          setError(error as ApiErrorResponse);
        } else {
          console.log('Unknown error:', error);
          setError(UnknownError(''));
        }
      } finally {
        setLoading(false);
      }
    }

    fetch();
  }, []);

  return [result, error, loading];
}

export function useGetIakBalance(): [number, ApiErrorResponse | undefined, boolean] {
  const [result, setResult] = useState(0);
  const [error, setError] = useState<ApiErrorResponse>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetch() {
      try {
        setResult(0);
        setError(undefined);
        setLoading(true);

        var res = await SuperadminServices.getIakBalance();
        setResult(res.data.amount);
      } catch (error) {
        if (error as ApiErrorResponse) {
          setError(error as ApiErrorResponse);
        } else {
          console.log('Unknown error:', error);
          setError(UnknownError(''));
        }
      } finally {
        setLoading(false);
      }
    }

    fetch();
  }, []);

  return [result, error, loading];
}
