import { Navbar } from 'flowbite-react';
import { ReactNode, useEffect, useState } from 'react';
import React from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import Logo from '../assets/images/logo.png';
import { logoutRedux } from '../redux/auth/auth.action';
import { useAppDispatch, useAppSelector } from '../redux/hook';
import { store } from 'redux/store';
import { useFetch } from 'hooks/useFetch';
import { UserTokenServices } from '_services/usertoken.services';
import { AuthToken } from '_interfaces/usertoken.interfaces';
// import useProtectedRoute from "../routes/routes";

const DashboardLayout = () => {
  const redux_state = store.getState();
  const { title, hasBack, } = useAppSelector((state) => state.globalState);
  const dispatch = useAppDispatch();
  // const [route] = useProtectedRoute();
  const navigate = useNavigate();

  const getUserToken = useFetch<AuthToken>(UserTokenServices.ValidateToken)
  useEffect(() => {
    getUserToken.request(redux_state.auth.token ?? "")
  }, [])
  const allow_create_wage_request = () => {
    const allow_list: string[] = process.env.REACT_APP_WAGE_REQUEST_SCRIPT?.split("|") ?? []
    return allow_list.filter((l) => l === getUserToken.data?.employeeCode.toString()).length > 0
  }


  const [sidebarStatus, setSidebarStatus] = useState(window.innerWidth >= 768);

  // https://tailwindcomponents.com/component/responsive-sidebar-tailwindcss
  return (
    <>
      <Navbar fluid={true} rounded={true} hidden={sidebarStatus} className="justify-start">
        <Navbar.Toggle
          aria-hidden={false}
          style={{ display: 'block' }}
          onClick={() => setSidebarStatus(!sidebarStatus)}
        />
        <Navbar.Brand href="/">
          <img src={Logo} alt="gajiku" className="mr-3 h-6 sm:h-9" />
          <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">Gajiku Admin</span>
        </Navbar.Brand>
        <div />
      </Navbar>

      <div
        className={
          'sidebar fixed top-0 bottom-0 lg:left-0 p-2 w-[300px] overflow-y-auto text-center bg-gray-900 z-50 ' +
          (sidebarStatus ? '' : 'hidden')
        }
      >
        <div className="text-gray-100 text-xl">
          <div className="p-2.5 mt-1 flex items-center">
            <img src={Logo} alt="gajiku" className="h-10" />
            <h1 className="font-bold text-gray-200 text-[15px] ml-3">Gajiku Admin</h1>
            <i
              className="bi bi-x cursor-pointer ml-28 lg:hidden"
              onClick={() => {
                setSidebarStatus(false);
              }}
            />
          </div>
          <div className="my-2 bg-gray-600 h-[1px]" />
        </div>

        <SidebarItem title="Dashboard" to={'/'} icon={<i className={'bi bi-bar-chart'} />} />
        <SidebarItem title="Company" to={'#'} icon={<i className={'bi bi-building'} />}>
          <SidebarItem title="Company List" to={'/company'} />
          <SidebarItem title="Kalender Kerja (Template)" to={'/calendar/template'} />
        </SidebarItem>

        <SidebarItem title="EWA" to={'#'} icon={<i className={'bi bi-cash'} />}>
          <SidebarItem title="Transaction" to={'/ewa/transaction'} />
        </SidebarItem>

        <SidebarItem title="Digital Product" to={'#'} icon={<i className={'bi bi-phone'} />}>
          <SidebarItem title="List Digital Product" to={'/digital-product/list'} />
          <SidebarItem title="Transactions" to={'/digital-product/transactions'} />
        </SidebarItem>

        <SidebarItem title="Script" to={'#'} icon={<i className={'bi bi-code'} />}>
          { allow_create_wage_request() &&
          <SidebarItem title="Create Wage Request" to={'/script/create-wage-request'} /> }
          <SidebarItem title="Update Employee EWA by CSV" to={'/script/upload-employee-ewa'} />
          <SidebarItem title="Update Salary" to={'/script/update-salary'} />
          <SidebarItem title="Upload Allowance By CSV" to={'/script/upload-allowance'} />
          <SidebarItem title="Reset Pin" to={'/script/reset-pin'} />
        </SidebarItem>

        <div
          className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-600 text-white"
          onClick={() => dispatch(logoutRedux())}
        >
          <i className="bi bi-box-arrow-in-right" />
          <span className="text-[15px] ml-4 text-gray-200 font-bold"> Logout </span>
        </div>
      </div>
      <div className={'w-100 bg-slate-100 min-h-screen ' + (sidebarStatus ? 'sm:pl-[300px] ' : '')}>
        <div className="p-10">
          <div className="flex flex-row gap-4 items-end mb-10">
            {hasBack && (
              <p className="text-base font-bold hover:text-blue-400 cursor-pointer" onClick={() => navigate(-1)}>
                &larr; Back
              </p>
            )}
            <h1 className="text-3xl font-semibold">{title}</h1>
          </div>
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;

const SidebarItem = (props: { title: string; to: string; icon?: JSX.Element; children?: ReactNode }) => {
  const [submenu, setSubmenu] = useState(false);

  if (React.Children.count(props.children) === 0) {
    return (
      <Link
        to={props.to}
        className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-600 text-white"
      >
        {props.icon}
        <span className="text-[15px] ml-4 text-gray-200 font-bold">{props.title}</span>
      </Link>
    );
  }

  return (
    <>
      <div>
        <div
          className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-600 text-white"
          onClick={() => {
            setSubmenu(!submenu);
          }}
        >
          {props.icon}
          <div className="flex justify-between w-full items-center">
            <span className="text-[15px] ml-4 text-gray-200 font-bold">{props.title}</span>
            <span className="text-sm rotate-180" id="arrow">
              <i className={submenu ? 'bi bi-chevron-down' : 'bi bi-chevron-up'} />
            </span>
          </div>
        </div>
        <div className={submenu ? '' : 'hidden'}>
          <div className="ml-4">{props.children}</div>
        </div>
      </div>
    </>
  );
};
