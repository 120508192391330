import { Badge, Button, Card, Label, Table, TextInput, ToggleSwitch, Select, Pagination } from "flowbite-react";
import moment from "moment";
import { useEffect, useState } from "react";
import { hideLoading, setHeader } from "redux/global/global.action";
import { useAppDispatch } from "redux/hook";
import useDigitalProduct from "./useDigitalProduct";


const TransactionDigitalProduct = () => {
  const dispatch = useAppDispatch();
  const { GetList, listTransactions } = useDigitalProduct();

  const now = new Date()
  useEffect(() => {
    dispatch(setHeader({ title: "Transaction" }));
    dispatch(hideLoading())
    setParams({
      ...params,
      dateFrom: moment(now).format("YYYY-MM-DD"),
      dateTo: moment(now).format("YYYY-MM-DD")
    })
  }, []);

  const [params, setParams] = useState({
    page: 1,
    size: 50,
    dateFrom: moment(new Date()).format("YYYY-MM-DD"),
    dateTo: moment(new Date()).format("YYYY-MM-DD")
  })

  useEffect(() => {
    GetList(params)
  }, [params?.page])

  const badgeColor = (status: string) => {
    switch (status) {
      case "PENDING":
        return "warning"
      case "SUCCESS":
        return "info"
      case "FAILED":
        return "failure"
      default:
        break;
    }
  }

  const badgeText = (status: string) => {
    switch (status) {
      case "PENDING":
        return "Pending"
      case "SUCCESS":
        return "Success"
      case "FAILED":
        return "Failed"
      default:
        break;
    }
  }

  return <>
    <Card>
      <div>
        <div><Label>Choose Date</Label></div>
        <div className="flex gap-4 mt-2">
          <div className="min-w-[200px]"><TextInput value={params?.dateFrom} onChange={(e) => setParams({...params, dateFrom: e.target.value})} type={"date"}/></div>
          <div className="min-w-[200px]"><TextInput value={params?.dateTo } onChange={(e) => setParams({...params, dateTo: e.target.value})} type={"date"} /></div>
        </div>
        <div className="flex gap-2 mt-3">
          <Button color={"info"} disabled={(params?.dateFrom === "" && params?.dateTo === "")} onClick={() => GetList(params)}>Apply</Button>
          <Button color={"light"} onClick={async () => {
            await setParams({...params, dateFrom: moment(new Date()).format("YYYY-MM-DD"), dateTo: moment(new Date()).format("YYYY-MM-DD")}) 
            GetList(params)
          }}>Reset</Button>
        </div>
      </div>
    </Card>
    <div className="mt-3">
    <Table striped={true}>
      <Table.Head className="!bg-primary !text-white">
        <Table.HeadCell> Date </Table.HeadCell>
        <Table.HeadCell> Company Name </Table.HeadCell>
        <Table.HeadCell> Employee Name </Table.HeadCell>
        <Table.HeadCell> Product Category </Table.HeadCell>
        <Table.HeadCell> Product Name </Table.HeadCell>
        <Table.HeadCell> Amount </Table.HeadCell>
        <Table.HeadCell> Fee </Table.HeadCell>
        <Table.HeadCell> Total </Table.HeadCell>
        <Table.HeadCell> Status </Table.HeadCell>
        <Table.HeadCell> Info </Table.HeadCell>
      </Table.Head>
      <Table.Body className="divide-y">
        {
          (listTransactions?.digitalProducts?.length === 0 || listTransactions?.digitalProducts?.length === undefined) && (
            <Table.Row>
              <Table.Cell className="text-center" colSpan={12}>Tidak ada data</Table.Cell>    
            </Table.Row>    
          )
        }
        {
          listTransactions?.digitalProducts?.map((tr, i) => (
            <Table.Row key={i} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <Table.Cell className="whitespace-nowrap min-w-[200px] text-gray-900 dark:text-white">{moment(tr.createdAt).format("DD MMM YYYY")}</Table.Cell>
              <Table.Cell className="whitespace-nowrap min-w-[200px] text-gray-900 dark:text-white">{tr.companyName}</Table.Cell>
              <Table.Cell className="whitespace-nowrap min-w-[200px] text-gray-900 dark:text-white">{tr.employeeName}</Table.Cell>
              <Table.Cell className="whitespace-nowrap min-w-[200px] text-gray-900 dark:text-white capitalize">
                {tr.productCategory}
              </Table.Cell>
              <Table.Cell className="whitespace-nowrap min-w-[200px] text-gray-900 dark:text-white">{tr.productName}</Table.Cell>
              <Table.Cell className="whitespace-nowrap min-w-[200px] text-gray-900 dark:text-white">{tr.price?.toLocaleString("id-ID", {style: "currency", currency: "IDR", minimumFractionDigits: 0})}</Table.Cell>
              <Table.Cell className="whitespace-nowrap min-w-[200px] text-gray-900 dark:text-white">{tr.fee?.toLocaleString("id-ID", {style: "currency", currency: "IDR", minimumFractionDigits: 0})}</Table.Cell>
              <Table.Cell className="whitespace-nowrap min-w-[200px] text-gray-900 dark:text-white">{tr.total?.toLocaleString("id-ID", {style: "currency", currency: "IDR", minimumFractionDigits: 0})}</Table.Cell>
              <Table.Cell className="whitespace-nowrap min-w-[200px] text-gray-900 dark:text-white">
                <Badge color={badgeColor(tr.status)}>{badgeText(tr.status)}</Badge>
              </Table.Cell>
              <Table.Cell className="whitespace-nowrap min-w-[200px] text-gray-900 dark:text-white">
                {tr.sn}
              </Table.Cell>
            </Table.Row>
          ))
        }
      </Table.Body>
    </Table>
    <Pagination
      currentPage={listTransactions?.pagination?.currentPage ?? 1}
      totalPages={listTransactions?.pagination ? listTransactions?.pagination?.lastPage : listTransactions?.pagination?.currentPage ?? 0}
      onPageChange={ (page:number)=> {
        setParams({
          ...params,
          page: page
        })
      }}
    />
    </div>
  </>
}

export default TransactionDigitalProduct;