import {
  faArrowLeft,
  faArrowRight,
  faArrowUp19,
  faChartLine,
  faCheck,
  faMinus,
  faPersonArrowUpFromLine,
  faWallet,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import React from 'react';
import { toast } from 'react-toastify';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useGetFlipBalance, useGetIakBalance } from './balance.hook';
import { useSuperadminGetEwaTransaction } from './ewa-transaction.hook';
import { EwaTransactionDayToDay, EwaTransactionSummary } from '../../_interfaces';
import { ApiErrorResponse, UnknownError } from '../../_network/response';
import { SuperadminServices } from '../../_services';
import SummaryCard from '../../components/card/summary-card';
import { hideLoading, setHeader, showLoading } from '../../redux/global/global.action';
import { useAppDispatch } from '../../redux/hook';

const Dashboard = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setHeader({ title: 'Dashboard' }));
    dispatch(hideLoading());
  }, []);
  const now = moment.tz(Date.now(), 'Asia/Jakarta');
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Agt', 'Sep', 'Okt', 'Nov', 'Des'];

  const [year, setYear] = useState(now.year());
  const [month, setMonth] = useState(now.month() + 1);
  const nextMonth = () => {
    if (month + 1 > 12) {
      setMonth(month + 1 - 12);
      setYear(year + 1);
    } else {
      setMonth(month + 1);
    }
  };

  const prevMonth = () => {
    if (month - 1 < 1) {
      setMonth(month - 1 + 12);
      setYear(year - 1);
    } else {
      setMonth(month - 1);
    }
  };

  const [ewa_summary, ewa_summary_error, ewa_summary_loading] = useSuperadminGetEwaTransaction(year, month);
  const [flip_balance_amount, flip_balance_error, flip_balance_loading] = useGetFlipBalance();
  const [iakBalanceAmount, iakBalanceError, iakBalanceLoading] = useGetIakBalance();
  const [ewa_day_to_day, ,] = useFetchEwaTransactionDayToDay(year, month);

  return (
    <>
      <div className="flex items-center justify-center">
        <div className="inline-flex">
          <button
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-full"
            onClick={prevMonth}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
          <div className="flex items-center mr-2 ml-2 align-middle text-center">
            {monthNames[month - 1]} {year}
          </div>
          <button
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-full"
            onClick={nextMonth}
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </button>
        </div>
      </div>
      <div className="flex flex-wrap">
        {/* ewa_summary, ewa_summary_loading, ewa_summary_error */}
        {ewa_summary === undefined || ewa_summary_error !== undefined || ewa_summary_loading === true ? (
          <SummaryCard title={'Trx Amount'} value="" is_loading={true} icon="" icon_bg_color="bg-red-500" subtitle="" />
        ) : (
          <SummaryCard
            title={'Trx Amount'}
            value={ewa_summary.totalValue.toLocaleString('id-ID', {
              style: 'currency',
              currency: 'IDR',
              minimumFractionDigits: 0,
            })}
            icon={<FontAwesomeIcon icon={faChartLine} />}
            icon_bg_color="bg-red-500"
            is_loading={false}
            subtitle={
              'Last Update: ' +
              moment.tz((ewa_summary as EwaTransactionSummary).lastUpdate, 'Asia/Jakarta').format('D-MM-YYYY HH:mm Z')
            }
          />
        )}

        {ewa_summary === undefined || ewa_summary_error !== undefined || ewa_summary_loading === true ? (
          <SummaryCard title={'Trx Count'} value="" is_loading={true} icon="" icon_bg_color="bg-red-500" subtitle="" />
        ) : (
          <SummaryCard
            title={'Trx Count'}
            value={ewa_summary.totalCount.toLocaleString('id-ID', { style: 'decimal' })}
            icon={<FontAwesomeIcon icon={faArrowUp19} />}
            icon_bg_color="bg-red-500"
            is_loading={false}
            subtitle={
              'Last Update: ' +
              moment.tz((ewa_summary as EwaTransactionSummary).lastUpdate, 'Asia/Jakarta').format('D-MM-YYYY HH:mm Z')
            }
          />
        )}

        {ewa_summary === undefined || ewa_summary_error !== undefined || ewa_summary_loading === true ? (
          <SummaryCard title={'Trx Users'} value="" is_loading={true} icon="" icon_bg_color="bg-red-500" subtitle="" />
        ) : (
          <SummaryCard
            title={'Trx Users'}
            value={ewa_summary.totalUniqueUser.toLocaleString('id-ID', { style: 'decimal' })}
            icon={<FontAwesomeIcon icon={faPersonArrowUpFromLine} />}
            icon_bg_color="bg-red-500"
            is_loading={false}
            subtitle={
              'Last Update: ' +
              moment.tz((ewa_summary as EwaTransactionSummary).lastUpdate, 'Asia/Jakarta').format('D-MM-YYYY HH:mm Z')
            }
          />
        )}
      </div>

      <div className="flex mb-8">
        {flip_balance_error !== undefined || flip_balance_loading === true ? (
          <SummaryCard
            title={'Flip Balance'}
            value=""
            is_loading={true}
            icon=""
            icon_bg_color="bg-pink-500"
            subtitle=""
          />
        ) : (
          <SummaryCard
            title={'Flip Balance'}
            value={flip_balance_amount.toLocaleString('id-ID', {
              style: 'currency',
              currency: 'IDR',
              minimumFractionDigits: 0,
            })}
            icon={<FontAwesomeIcon icon={faWallet} />}
            icon_bg_color="bg-pink-500"
            is_loading={false}
            subtitle={
              flip_balance_amount > 30000000 ? (
                <span className="text-green-500 mr-2">
                  <FontAwesomeIcon icon={faCheck} /> Balance OK
                </span>
              ) : (
                <span className="text-red-500 mr-2">
                  <FontAwesomeIcon icon={faMinus} /> Need Top Up Balance
                </span>
              )
            }
          />
        )}

        {iakBalanceError !== undefined || iakBalanceLoading === true ? (
          <SummaryCard
            title={'IAK Balance'}
            value=""
            is_loading={true}
            icon=""
            icon_bg_color="bg-pink-500"
            subtitle=""
          />
        ) : (
          <SummaryCard
            title={'IAK Balance'}
            value={iakBalanceAmount.toLocaleString('id-ID', {
              style: 'currency',
              currency: 'IDR',
              minimumFractionDigits: 0,
            })}
            icon={<FontAwesomeIcon icon={faWallet} />}
            icon_bg_color="bg-pink-500"
            is_loading={false}
            subtitle={
              iakBalanceAmount > 3000000 ? (
                <span className="text-green-500 mr-2">
                  <FontAwesomeIcon icon={faCheck} /> Balance OK
                </span>
              ) : (
                <span className="text-red-500 mr-2">
                  <FontAwesomeIcon icon={faMinus} /> Need Top Up Balance
                </span>
              )
            }
          />
        )}
      </div>
      <div className="">
        <ResponsiveContainer width={'100%'} className="object-center block" height={300}>
          <LineChart
            width={800}
            height={400}
            data={ewa_day_to_day.transactions.map((t) => {
              const dateAsStr = t.dateAt.toString();
              return { name: dateAsStr.slice(dateAsStr.length - 2), value: t.cummulativeAmount };
            })}
            margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" name="Day" />
            <YAxis
              mirror={true}
              tickFormatter={(tick) => {
                return new Intl.NumberFormat('id').format(tick);
              }}
            />
            <Tooltip formatter={(value: number) => new Intl.NumberFormat('id').format(value)} />
            <Legend />
            <Line type="monotone" dataKey="value" name="Cummulative" stroke="#8884d8" activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default Dashboard;

export function useFetchEwaTransactionDayToDay(
  year: number,
  month: number,
): [
  EwaTransactionDayToDay,
  React.Dispatch<React.SetStateAction<EwaTransactionDayToDay>>,
  ApiErrorResponse | undefined,
] {
  const [result, setResult] = useState<EwaTransactionDayToDay>({ transactions: [] });
  const [error, setError] = useState<ApiErrorResponse>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    async function fetch(_year: number, _month: number) {
      try {
        dispatch(showLoading());
        var res = await SuperadminServices.getEwaTransactionDayToDay(_year, _month);
        setResult(res.data);
      } catch (error) {
        if (error as ApiErrorResponse) {
          setError(error as ApiErrorResponse);
          toast.error((error as ApiErrorResponse).message);
        } else {
          console.log('Unknown error:', error);
          setError(UnknownError(''));
          toast.error('Internal Error');
        }
      } finally {
        dispatch(hideLoading());
      }
    }
    fetch(year, month);
  }, [month]);

  return [result, setResult, error];
}
