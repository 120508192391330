import AuthActionTypes from "./auth.types";

interface AuthState {
  // eslint-disable-next-line @typescript-eslint/ban-types
  token: string | null;
  failure: boolean;
}

const INITIAL_STATE: AuthState = {
  token: null,
  failure: false,
};

const AuthReducer = (state = INITIAL_STATE, action: any): AuthState => {
  switch (action.type) {
    case AuthActionTypes.LOGIN:
      return {
        ...state,
        failure: false,
      };
    case AuthActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload,
      };
    case AuthActionTypes.LOGIN_FAILURE:
      return {
        ...state,
        failure: true,
      };
    case AuthActionTypes.LOGOUT:
      return {
        ...state,
        token: null,
      };
    default:
      return state;
  }
};

export default AuthReducer;
