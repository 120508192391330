import { Button, Label, Spinner, Table, TextInput } from "flowbite-react";
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { hideLoading, setHeader, showLoading } from "../../redux/global/global.action";
import { useAppDispatch } from "../../redux/hook";
import { CompanyPeriod, CompanyPeriodSetting, CompanyTax, FormState } from "../../_interfaces";
import { ApiErrorResponse, UnknownError } from "../../_network/response";
import { SuperadminServices } from "../../_services";
import * as yup from 'yup'
import { toast } from 'react-toastify';
import moment from 'moment';
import { FiTrash } from "react-icons/fi";

const CompanyDetailPeriod = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(
      setHeader({
        title: "Company Detail - Period Setting",
        hasBack: true,
      })
    );
    dispatch(hideLoading())
  }, []);
  const params = useParams();
  const company_id: string = params.id ? params.id : ""

  // 1. Hook for Getter
  const [result, setResult, error] = useFetch(company_id)
  useEffect(() => { 
    reset(result.periodSetting);
  }, [result]); // to reset value for yup form validator
  
  // 2. Hook for Updater
  const [updating, setUpdating] = useState(false)
  const [formBackendError, setFormBackendError]   = useState({ startPeriod: "", endPeriod: "" })

  const PeriodSettingValidationSchema = yup.object().shape({
    startPeriod: yup.number().min(1).max(31),
    endPeriod: yup.number().min(1).max(31),
  })

  const { register, handleSubmit, formState: { errors  }, reset, watch, setValue } = useForm<CompanyPeriodSetting>({ mode: 'onChange', resolver: yupResolver(PeriodSettingValidationSchema) });

  const onSubmitHandler = async (data: CompanyPeriodSetting) => {
    try {
      setUpdating(true)
      setFormBackendError({startPeriod: "", endPeriod:""})
      await SuperadminServices.updateCompanyPeriodSetting({
        companyId: company_id, 
        startPeriod: data.startPeriod, 
        endPeriod: data.endPeriod,
        blackoutDays: data.blackoutDays,
      });
      toast.success("Update data sukses")
    } catch (error) {
      if(error as ApiErrorResponse){
        (error as ApiErrorResponse).other_errors.forEach( (e) => {
          switch(e.field){
              case "startPeriod": setFormBackendError({...formBackendError, startPeriod: e.message} ); break;
              case "endPeriod": setFormBackendError({...formBackendError, endPeriod: e.message} ); break;
          }
        })
        toast.error((error as ApiErrorResponse).message)
      } else {
        console.log("Unknown error:",error);
        toast.error("Internal Error")
      }
    } finally {
      setUpdating(false)
    }
  };

  // https://flowbite.com/docs/components/tabs/
  return <>
    <ul className="flex mb-4 flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
      <li className="mr-2">
        <Link to={"/company/" + params.id} className="inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"> Profil </Link>
      </li>
      <li className="mr-2">
        <Link to={"/company/" + params.id + "/tax"} className="inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"> Data Wajib Pajak </Link>
      </li>
      <li className="mr-2">
        <Link to={"/company/" + params.id + "/period"} className="inline-block p-4 text-blue-600 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"> Periode </Link>
      </li>
      <li className="mr-2">
        <Link to={"/company/" + params.id + "/ewa"} aria-current="page" className="inline-block p-4 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500"> EWA </Link>
      </li>
      <li className="mr-2">
        <Link to={"/company/" + params.id + "/loan"} aria-current="page" className="inline-block p-4 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500"> Pinjaman </Link>
      </li>
    </ul>
   
    <form className="flex flex-col gap-4 p-6 border border-dashed border-neutral-300 rounded-lg" onSubmit={handleSubmit(onSubmitHandler)}>
      <div className="grid grid-cols-4 gap-4">
        <div>
          <div className="mb-2 block"> <Label htmlFor="startPeriod" value="Periode Mulai"/></div>
          <TextInput {...register("startPeriod")} id="startPeriod" defaultValue={result.periodSetting.startPeriod} />
          <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.startPeriod && <>{errors.startPeriod.message}</>) || (formBackendError.startPeriod != "" && <>{formBackendError.startPeriod}</>)  }</p>
        </div>
        <div>
          <div className="mb-2 block"> <Label htmlFor="endPeriod" value="Periode Akhir"/></div>
          <TextInput {...register("endPeriod")} id="endPeriod" defaultValue={result.periodSetting.endPeriod} />
          <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.endPeriod && <>{errors.endPeriod.message}</>) || (formBackendError.endPeriod != "" && <>{formBackendError.endPeriod}</>)  }</p>
        </div>
      </div>

      <div>
        <Label value="Blackout"/>
        <Table striped className="mt-2">
          <Table.Head>
            <Table.HeadCell scope='col'>#</Table.HeadCell>
            <Table.HeadCell scope='col'>Tanggal Mulai</Table.HeadCell>
            <Table.HeadCell scope='col'>Tanggal Selesai</Table.HeadCell>
            <Table.HeadCell scope='col'>Tindakan</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            { watch('blackoutDays')?.map((b, i) => (
              <Table.Row key={i}>
                <Table.Cell>{i+1}</Table.Cell>
                <Table.Cell>
                  <TextInput 
                    type='number'
                    min={1}
                    max={31}
                    { ...register(`blackoutDays.${i}.start`, {
                      required: 'Wajib diisi',
                      valueAsNumber: true,
                    }) }
                  />
                </Table.Cell>
                <Table.Cell>
                  <TextInput 
                    type='number'
                    min={1}
                    max={31}
                    { ...register(`blackoutDays.${i}.end`, {
                      required: 'Wajib diisi',
                      valueAsNumber: true,
                    }) }
                  />
                </Table.Cell>
                <Table.Cell>
                  <Button 
                    color='failure'
                    size='sm'
                    onClick={() => {
                      const list = [ ...watch('blackoutDays') ?? [] ];
                      list.splice(i, 1);
                      setValue('blackoutDays', list)
                    }}
                  >
                    <FiTrash />
                  </Button>
                </Table.Cell>
              </Table.Row>
            )) }
            { (watch('blackoutDays')?.length ?? 0) === 0 && (
              <Table.Row>
                <Table.Cell colSpan={4} className="text-sm italic text-neutral-600 text-center py-8">Tidak ada range blackout</Table.Cell>
              </Table.Row>
            ) }
            {/* <Table.Row>
              <Table.Cell colSpan={4} className="flex items-center justify-center py-1 w-[300%]">
              </Table.Cell>
            </Table.Row> */}
          </Table.Body>
        </Table>
        <div className="flex w-full py-2 items-center">
          <Button
            color='dark'
            size='sm'
            onClick={() => {
              const list = [ ...watch('blackoutDays') ?? [] ];
              list.push({ start: 1, end: 31 });
              setValue('blackoutDays', list);
            }}
          >
            + Tambah Range
          </Button>
        </div>
      </div>

      <div className="pt-6 flex items-center justify-center">
        <Button  type="submit" disabled={ updating == true}>
          { updating ? <div className="mr-3"> <Spinner size="sm" light={true} /></div> : null }
          Update 
        </Button>
      </div>
      
      
    </form>
    <br></br>

    <Table striped={true}>
      <Table.Head>
        <Table.HeadCell> No </Table.HeadCell>
        <Table.HeadCell> Id </Table.HeadCell>
        <Table.HeadCell> Periode </Table.HeadCell>
      </Table.Head>
      <Table.Body className="divide-y">
        {
          (result !== undefined && result.periodList.length <= 0)  ?
          <Table.Cell colSpan={4}> Tidak ada data perusahaan</Table.Cell>
          :
          result.periodList.map( (c, i) => {
            return <Table.Row key={"pl-"+c.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                {(i+1).toString()}
              </Table.Cell>
              <Table.Cell className="whitespace-nowrap"> {c.id} </Table.Cell>
              <Table.Cell className="whitespace-nowrap"> { (moment(c.startPeriod)).format("DD-MM-YYYY") + " s/d " + (moment(c.endPeriod)).format("DD-MM-YYYY")} </Table.Cell>
              
            </Table.Row>
          })
        }
      </Table.Body>
    </Table>
  </>
};

export default CompanyDetailPeriod;


export function useFetch(company_id: string) :[CompanyPeriod, React.Dispatch<React.SetStateAction<CompanyPeriod>>, ApiErrorResponse | undefined] {
  const [result, setResult] = useState<CompanyPeriod>({
    companyId: company_id, 
    periodSetting: {
      startPeriod: 1, 
      endPeriod:31,
      blackoutDays: [],
    }, 
    periodList:[],
  });
  const [error, setError] = useState<ApiErrorResponse>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    async function fetch(_company_id: string) {
      try {
        dispatch(showLoading())
        var res = await SuperadminServices.getCompanyPeriod(_company_id);
        setResult(res.data);
      } catch (error) {
        if(error as ApiErrorResponse){
          setError(error as ApiErrorResponse);
          toast.error((error as ApiErrorResponse).message)
        }  else {
          console.log("Unknown error:",error);
          setError(UnknownError(""));
          toast.error("Internal Error")
        }
      } finally {
        dispatch(hideLoading())
      }
    }
    fetch(company_id)
  }, []);
  
  return [result, setResult, error];
}
