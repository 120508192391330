import {
  Badge,
  Button,
  Card,
  Label,
  Table,
  TextInput,
  ToggleSwitch,
  Select,
  Pagination,
} from "flowbite-react";
import { useEffect, useState } from "react";
import { hideLoading, setHeader } from "redux/global/global.action";
import { useAppDispatch } from "redux/hook";
import {
  DigitalProduct,
  UpdateDigitalProductRequest,
} from "_interfaces/digitalProduct.interface";
import { TncModal } from "./components/modal/Tnc.modal";
import { VoucherModal } from "./components/modal/voucher.modal";
import useDigitalProduct from "./useDigitalProduct";

const ListDigitalProduct = () => {
  const dispatch = useAppDispatch();
  const { GetDigitalProduct, listDigitalProduct, UpdateDigitalProduct } =
    useDigitalProduct();
  const [params, setParams] = useState({
    page: 1,
    size: 50,
    type: "pulsa",
  });

  const [listData, setListData] = useState<DigitalProduct[]>();
  const [loading, setLoading] = useState(false);
  const [dataPayload, setDataPayload] = useState<UpdateDigitalProductRequest>();
  const [showTncModal, setShowTncModal] = useState<{
    show: boolean;
    code: string;
  }>({
    show: false,
    code: "",
  });

  const [showVoucherModal, setShowVoucherModal] = useState<{
    show: boolean;
    code: string;
  }>({
    show: false,
    code: "",
  });

  useEffect(() => {
    dispatch(setHeader({ title: "List Digital Product" }));
    dispatch(hideLoading());
  }, []);

  useEffect(() => {
    listDigitalProduct?.digitalProducts &&
      setListData(listDigitalProduct?.digitalProducts);
  }, [listDigitalProduct]);

  useEffect(() => {
    GetDigitalProduct(params);
  }, [params?.page]);

  return (
    <>
      <Card>
        <div>
          <div>
            <Label>Choose Digital Product Category</Label>
          </div>
          <div className="flex gap-4 mt-2">
            <div className="min-w-[300px]">
              <Select
                id="type"
                value={params?.type}
                onChange={(e) => setParams({ ...params, type: e.target.value })}
              >
                <option value="pulsa">Pulsa</option>
                <option value="pln">PLN</option>
                <option value="data">Paket Data</option>
                <option value="voucher">Voucher Deals</option>
              </Select>
            </div>
            <Button onClick={() => GetDigitalProduct(params)} color={"info"}>
              Apply
            </Button>
          </div>
        </div>
      </Card>
      <div className="mt-3">
        <Table striped={true}>
          <Table.Head className="!bg-primary !text-white">
            <Table.HeadCell> Group Name </Table.HeadCell>
            <Table.HeadCell> Provider </Table.HeadCell>
            <Table.HeadCell> Product Code </Table.HeadCell>
            <Table.HeadCell> Name </Table.HeadCell>
            <Table.HeadCell> Availability </Table.HeadCell>
            <Table.HeadCell> Base Price </Table.HeadCell>
            <Table.HeadCell> Normal Price </Table.HeadCell>
            <Table.HeadCell> Discount </Table.HeadCell>
            <Table.HeadCell> Admin Fee </Table.HeadCell>
            <Table.HeadCell> Display Price </Table.HeadCell>
            <Table.HeadCell> Status </Table.HeadCell>
            <Table.HeadCell> </Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {listData?.map((dp, i) => (
              <Table.Row
                key={i}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              >
                <Table.Cell className="whitespace-nowrap min-w-[200px] text-gray-900 dark:text-white">
                  <div className="flex">
                    <img src={dp.icon} className="max-w-[40px] mr-2" alt="" />
                    {dp.description}
                  </div>
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap min-w-[200px] text-gray-900 dark:text-white">
                  {dp.provider}
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap min-w-[200px] text-gray-900 dark:text-white">
                  {dp.code}
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap min-w-[200px] text-gray-900 dark:text-white">
                  {dp.name}
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap min-w-[200px] text-gray-900 dark:text-white">
                  {dp?.isProviderActive ? (
                    <Badge color={"info"}>Available</Badge>
                  ) : (
                    <Badge color={"failure"}>Unvailable</Badge>
                  )}
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap min-w-[200px] text-gray-900 dark:text-white">
                  {dp.originalPrice?.toLocaleString("id-ID", {
                    style: "currency",
                    currency: "IDR",
                    minimumFractionDigits: 0,
                  })}
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap min-w-[250px] text-gray-900 dark:text-white">
                  <TextInput
                    value={dp.price}
                    type="number"
                    onChange={(e) => {
                      const list = [...listData];
                      list[i]["price"] = +e.target.value;
                      setListData(list);
                    }}
                  />
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap min-w-[250px] text-gray-900 dark:text-white">
                  <TextInput
                    value={dp.discount}
                    type="number"
                    onChange={(e) => {
                      const list = [...listData];
                      list[i]["discount"] = +e.target.value;
                      setListData(list);
                    }}
                  />
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap min-w-[250px] text-gray-900 dark:text-white">
                  <TextInput
                    value={dp.fee}
                    type="number"
                    onChange={(e) => {
                      const list = [...listData];
                      list[i]["fee"] = +e.target.value;
                      setListData(list);
                    }}
                  />
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap min-w-[200px] text-gray-900 dark:text-white">
                  {(dp.price - dp.discount)?.toLocaleString("id-ID", {
                    style: "currency",
                    currency: "IDR",
                    minimumFractionDigits: 0,
                  })}
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap  text-gray-900 dark:text-white">
                  <ToggleSwitch
                    checked={dp.isActive}
                    label=""
                    onChange={(e) => {
                      const list = [...listData];
                      list[i]["isActive"] = e;
                      setListData(list);
                    }}
                  />
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap min-w-[300px] flex gap-2 text-gray-900 dark:text-white">
                  <div className="">
                    <Button
                      color={"info"}
                      size={"xs"}
                      disabled={
                        dp.price === 0 || dp.price < dp.discount || loading
                      }
                      onClick={async () => {
                        setLoading(true);
                        let obj = {
                          id: dp.id,
                          price: dp.price,
                          discount: dp.discount,
                          fee: dp.fee,
                          isActive: dp.isActive,
                          detail: dp.detail,
                          voucherImage: dp.voucherImage,
                        };
                        await UpdateDigitalProduct(obj);
                        GetDigitalProduct(params);
                        setLoading(false);
                      }}
                    >
                      Save
                    </Button>
                  </div>
                  <div>
                    <Button
                      color={"info"}
                      size={"xs"}
                      onClick={() =>
                        setShowTncModal({
                          show: true,
                          code: dp?.code,
                        })
                      }
                    >
                      TnC
                    </Button>
                  </div>
                  {dp?.provider === "NGIFT" && (
                    <div>
                      <Button
                        color={"info"}
                        size={"xs"}
                        onClick={() =>
                          setShowVoucherModal({
                            show: true,
                            code: dp?.code,
                          })
                        }
                      >
                        Voucher
                      </Button>
                    </div>
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
            {(listData?.length === 0 || listData?.length === undefined) && (
              <Table.Row>
                <Table.Cell className="text-center" colSpan={12}>
                  Tidak ada data
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
        <Pagination
          currentPage={listDigitalProduct?.pagination?.currentPage ?? 1}
          totalPages={
            listDigitalProduct?.pagination
              ? listDigitalProduct?.pagination?.lastPage
              : listDigitalProduct?.pagination?.currentPage ?? 0
          }
          onPageChange={(page: number) => {
            setParams({
              ...params,
              page: page,
            });
          }}
        />
      </div>

      <TncModal
        show={showTncModal?.show}
        onClose={(close) => {
          setShowTncModal({
            show: false,
            code: "",
          });
          if(close) {
            GetDigitalProduct(params);
          }
        }}
        code={showTncModal?.code}
      />

      <VoucherModal
        show={showVoucherModal?.show}
        onClose={(close) =>
          setShowVoucherModal({
            show: close,
            code: "",
          })
        }
        code={showVoucherModal?.code}
      />
    </>
  );
};

export default ListDigitalProduct;
