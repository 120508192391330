import AWS, { S3 } from 'aws-sdk';
import { PutObjectRequest } from 'aws-sdk/clients/s3';

const s3_region = 'ap-southeast-1';

AWS.config.update({
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
});
const s3_bucket = new S3({
  params: { Bucket: process.env.REACT_APP_S3_BUCKET },
  region: s3_region,
});

const S3Upload = (f: Blob, key: string, onProgress: (p: number) => void) => {
  const params: PutObjectRequest = {
    ACL: 'public-read',
    Body: f,
    Bucket: process.env.REACT_APP_S3_BUCKET ?? '',
    Key: key,
  };

  return s3_bucket
    .upload(params)
    .on('httpUploadProgress', evt => {
      onProgress(Math.round((evt.loaded / evt.total) * 100));
    })
    .promise();
};

export const FileUploadApi = {
  S3Upload,
};
