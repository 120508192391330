import request from "../_network/request";

const uploadImage = (image: string, location: string) => {
  const data = new FormData();
  data.append("file", image);
  data.append("location", location);
  data.append("random-filename", "true");

  return request({
    url: "gajiku-upload/upload",
    method: "POST",
    data: data,
    headers: {
      apikey: process.env.REACT_APP_UPLOAD_API_KEY,
    },
  });
};


export const uploadServices = {
  uploadImage,
};
