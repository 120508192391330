import DashboardLayout from "../layout/dashboard";
import Dashboard from "../pages/dashboard/dashboard.page";
import Transaction from "../pages/ewa/transaction.page";
import CompanyList from "../pages/company/company-list.page";
import CompanyDetailProfile from "../pages/company/company-detail-profile.page";
import CompanyDetailTax from "../pages/company/company-detail-tax.page";
import CompanyDetailEwa from "../pages/company/company-detail-ewa.page";
import PageNotFound from "../pages/other/404.page";
import CompanyDetailPeriod from "../pages/company/company-detail-period.page";
import CalendarTemplatePage from "../pages/calendar/template.page";
import ListDigitalProduct from "../pages/digital-product/list-digital-product";
import TransactionDigitalProduct from "pages/digital-product/transactions";
import CompanyDetailLoan from "pages/company/company-detail-loan.page";
import CreateWageRequestPage from "pages/script/create-wage-request.page";
import ScriptUpdateSalaryPage from "pages/script/update-salary.page";
import ScriptUploadAllowancePage from "pages/script/upload-allowance";
import ScriptUpdateEmployeeEwaPage from "pages/script/update-employee-ewa";
import ScriptResetPinPage from "pages/script/reset-pin";

const useProtectedRoute = (): [any[]] => {
  const route = [
    {
      path: "/",
      element: <DashboardLayout />,
      name: "Dashboard",
      children: [
        { path: "/", element: <Dashboard />, index: true, },
        { path: "/company", element: <CompanyList />, index: true, },
        { path: "/company/:id", element: <CompanyDetailProfile />, },
        { path: "/company/:id/tax", element: <CompanyDetailTax />, },
        { path: "/company/:id/ewa", element: <CompanyDetailEwa />, },
        { path: "/company/:id/period", element: <CompanyDetailPeriod />, },
        { path: "/company/:id/loan", element: <CompanyDetailLoan />, },
        { path: "/calendar/template", element: <CalendarTemplatePage />, },
        { path: "/ewa/transaction", element: <Transaction />, index: true, },  
        { path: "/digital-product/list", element: <ListDigitalProduct />, index: true, },  
        { path: "/digital-product/transactions", element: <TransactionDigitalProduct />, index: true, },  
        { path: "/script/create-wage-request", element: <CreateWageRequestPage />, index: true, },  
        { path: "/script/update-salary", element: <ScriptUpdateSalaryPage />, index: true, },  
        { path: "/script/upload-allowance", element: <ScriptUploadAllowancePage />, index: true, },  
        { path: "/script/upload-employee-ewa", element: <ScriptUpdateEmployeeEwaPage />, index: true, },  
        { path: "/script/reset-pin", element: <ScriptResetPinPage />, index: true, },  
        
        { path: "*", element: <PageNotFound />,  }

      ],
    },
  ];

  return [route];
};

export default useProtectedRoute;
