import { Button, Checkbox, Label, Modal, Select, Spinner, Table, TextInput } from "flowbite-react";
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { hideLoading, setHeader, showLoading } from "../../redux/global/global.action";
import { useAppDispatch } from "../../redux/hook";
import { CompanyEwa, LimitBasedFee, TransactionBasedFee } from "../../_interfaces";
import { ApiErrorResponse, UnknownError } from "../../_network/response";
import { SuperadminServices } from "../../_services";
import * as yup from 'yup'
import { toast } from 'react-toastify';

const CompanyDetailEwa = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(
      setHeader({
        title: "Company Detail - EWA",
        hasBack: true,
      })
    );
    dispatch(hideLoading())
  }, [dispatch]);
  const params = useParams();
  const company_id: string = params.id ? params.id : ""

  // 1. Hook for Getter
  const [result, setResult] = useState<CompanyEwa>({ companyId: company_id, minimumRequestAmount: 10000, maximumRequestAmount: 0, percentageLimit :100, maxTransactionPerPeriod : 0, isActive:true, autoDisburseStatus :true, dailyAccrueStatus :true, feeType :"default", defaultFixedFee :35000, defaultPercentageFee :0, limitBasedFee:[], transactionBasedFees:[]});
  const [limitBasedFee, setLimitBasedFee] = useState<LimitBasedFee[]>([]);
  const [limitBasedFeeModal, setLimitBasedFeeModal] = useState<LimitBasedFeeState>({isOpen: "close", onSubmit: (data)=>{}, title: ""});
  const [transactionBasedFeeModal, setTransactionBasedFeeModal] = useState<TransactionBasedFeeState>({isOpen: "close", onSubmit: (data)=>{}, title: ""});
  const [transactionBasedFee, setTransactionBasedFee] = useState<TransactionBasedFee[]>([]);
  const [error, setError] = useState<ApiErrorResponse>();
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetch(_company_id: string) {
      try {
        dispatch(showLoading())
        var res = await SuperadminServices.getCompanyEwa(_company_id);
        setResult(res.data);
        setLimitBasedFee(res.data.limitBasedFee)
        setTransactionBasedFee(res.data.transactionBasedFees)
      } catch (error) {
        if(error as ApiErrorResponse){
          setError(error as ApiErrorResponse);
          toast.error((error as ApiErrorResponse).message)
        }  else {
          console.log("Unknown error:",error);
          setError(UnknownError(""));
          toast.error("Internal Error")
        }
      } finally {
        dispatch(hideLoading())
      }
    }

    fetch(company_id)
  }, []);
  useEffect(() => { reset() }, [result]); // to reset value for yup form validator

  // 2. Hook for Updater
  const [updating, setUpdating] = useState(false)
  const [formBackendError, setFormBackendError]   = useState({companyId: "", minimumRequestAmount: "", maximumRequestAmount: "", percentageLimit :"", maxTransactionPerPeriod :"", isActive :"", autoDisburseStatus :"", dailyAccrueStatus :"", feeType :"", defaultFixedFee :"", defaultPercentageFee :"" })

  const FormCompanyEwaSchema = yup.object().shape({
    minimumRequestAmount: yup.number().min(10000),
    maximumRequestAmount: yup.number().min(0),
    percentageLimit: yup.number().max(100).min(0),
    maxTransactionPerPeriod: yup.number().min(0),
    isActive : yup.bool(),
    autoDisburseStatus :yup.bool(),
    dailyAccrueStatus :yup.bool(),
    feeType: yup.string().is(["default", "tier_by_limit", "tier_by_transaction"]),
    defaultFixedFee : yup.number().min(0),
    defaultPercentageFee : yup.number().min(0).max(100),
    // limitBasedFee: [],
    // transactionBasedFees: [],
  })

  const { register, handleSubmit, formState: { errors  }, reset } = useForm<CompanyEwa>({ mode: 'onChange', resolver: yupResolver(FormCompanyEwaSchema) });

  const onSubmitHandler = async (data: CompanyEwa) => {
    data.limitBasedFee = []
    data.transactionBasedFees = []
    if (data.feeType == "tier_by_limit"){
      data.limitBasedFee = limitBasedFee
    } else if (data.feeType == "tier_by_transaction"){
      data.transactionBasedFees = transactionBasedFee
    }

    try {
      setUpdating(true)
      setFormBackendError({  companyId  :"", minimumRequestAmount :"", maximumRequestAmount: "", percentageLimit :"", maxTransactionPerPeriod :"", isActive :"", autoDisburseStatus :"", dailyAccrueStatus :"", feeType :"", defaultFixedFee :"", defaultPercentageFee :"" })
      data.companyId = company_id
      await SuperadminServices.updateCompanyEwa(data);
      toast.success("Update data sukses")
      window.location.reload();
    } catch (error) {
      if(error as ApiErrorResponse){
        setError(error as ApiErrorResponse);
        (error as ApiErrorResponse).other_errors.forEach( (e) => {
          switch(e.field){
              case "companyId" : setFormBackendError({...formBackendError, companyId: e.message} ); break;
              case "minimumRequestAmount": setFormBackendError({...formBackendError, minimumRequestAmount: e.message} ); break;
              case "maximumRequestAmount": setFormBackendError({...formBackendError, maximumRequestAmount: e.message} ); break;
              case "percentageLimit": setFormBackendError({...formBackendError, percentageLimit: e.message} ); break;
              case "maxTransactionPerPeriod": setFormBackendError({...formBackendError, maxTransactionPerPeriod: e.message} ); break;
              case "isActive": setFormBackendError({...formBackendError, isActive: e.message} ); break;
              case "autoDisburseStatus": setFormBackendError({...formBackendError, autoDisburseStatus: e.message} ); break;
              case "dailyAccrueStatus": setFormBackendError({...formBackendError, dailyAccrueStatus: e.message} ); break;
              case "feeType": setFormBackendError({...formBackendError, feeType: e.message} ); break;
              case "defaultFixedFee": setFormBackendError({...formBackendError, defaultFixedFee: e.message} ); break;
              case "defaultPercentageFee": setFormBackendError({...formBackendError, defaultPercentageFee: e.message} ); break;
          }
        })
        toast.error((error as ApiErrorResponse).message)
      } else {
        console.log("Unknown error:",error);
        setError(UnknownError(""));
        toast.error("Internal Error")
      }
    } finally {
      setUpdating(false)
    }
  };

  // https://flowbite.com/docs/components/tabs/


  return <>
    <ul className="flex mb-4 flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
      <li className="mr-2">
        <Link to={"/company/" + params.id} className="inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"> Profil </Link>
      </li>
      <li className="mr-2">
        <Link to={"/company/" + params.id + "/tax"} className="inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"> Data Wajib Pajak </Link>
      </li>
      <li className="mr-2">
        <Link to={"/company/" + params.id + "/period"} className="inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"> Periode </Link>
      </li>
      <li className="mr-2">
        <Link to={"/company/" + params.id + "/ewa"} aria-current="page" className="inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500"> EWA </Link>
      </li>
      <li className="mr-2">
        <Link to={"/company/" + params.id + "/loan"} aria-current="page" className="inline-block p-4 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500"> Pinjaman </Link>
      </li>
    </ul>


    <form key="main-form" className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmitHandler)}>
      <div>
        <Checkbox {...register("isActive")} defaultChecked={result?.isActive} />
        <Label htmlFor="isActive"> EWA Active</Label>
        <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.isActive && <>{errors.isActive.message}</>) || (formBackendError.isActive != "" && <>{formBackendError.isActive}</>)  }</p>
      </div>
      <div>
        <div className="mb-2 block"> <Label htmlFor="percentageLimit" value="Limit EWA Perushaan (Dalam %)"/></div>
        <TextInput {...register("percentageLimit")} type="number" id="percentageLimit" value={result?.percentageLimit} onWheel={(e) => e.currentTarget.blur()} onChange={(e)=>{ setResult({...result, percentageLimit: Number(e.target.value) }) }} />
        <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.percentageLimit && <>{errors.percentageLimit.message}</>) || (formBackendError.percentageLimit != "" && <>{formBackendError.percentageLimit}</>)  }</p>
      </div>
      <div>
        <div className="mb-2 block"> <Label htmlFor="minimumRequestAmount" value="Minimum Penarikan (Dalam Rp)"/></div>
        <TextInput {...register("minimumRequestAmount")} type="number" id="minimumRequestAmount" value={result?.minimumRequestAmount} onWheel={(e) => e.currentTarget.blur()} onChange={(e)=>{ setResult({...result, minimumRequestAmount: Number(e.target.value) }) }} />
        <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.minimumRequestAmount && <>{errors.minimumRequestAmount.message}</>) || (formBackendError.minimumRequestAmount != "" && <>{formBackendError.minimumRequestAmount}</>)  }</p>
      </div>
      <div>
        <div className="mb-2 block"> <Label htmlFor="maximumRequestAmount" value="Maximum Penarikan (Dalam Rp, Apabila 0 berarti tidak ada limit)"/></div>
        <TextInput {...register("maximumRequestAmount")} type="number" id="maximumRequestAmount" value={result?.maximumRequestAmount} onWheel={(e) => e.currentTarget.blur()} onChange={(e)=>{ setResult({...result, maximumRequestAmount: Number(e.target.value) }) }} />
        <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.maximumRequestAmount && <>{errors.maximumRequestAmount.message}</>) || (formBackendError.maximumRequestAmount != "" && <>{formBackendError.maximumRequestAmount}</>)  }</p>
      </div>
      <div>
        <div className="mb-2 block"> <Label htmlFor="maxTransactionPerPeriod" value="Maximum Transaksi per Periode"/></div>
        <TextInput {...register("maxTransactionPerPeriod")} type="number" id="maxTransactionPerPeriod"  value={result?.maxTransactionPerPeriod} onWheel={(e) => e.currentTarget.blur()} onChange={(e)=>{ setResult({...result, maxTransactionPerPeriod: Number(e.target.value) }) }} />
        <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.maxTransactionPerPeriod && <>{errors.maxTransactionPerPeriod.message}</>) || (formBackendError.maxTransactionPerPeriod != "" && <>{formBackendError.maxTransactionPerPeriod}</>)  }</p>
      </div>

      <div>
        <Checkbox {...register("autoDisburseStatus")} defaultChecked={result?.autoDisburseStatus} />
        <Label htmlFor="autoDisburseStatus"> Auto Approved</Label>
        <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.autoDisburseStatus && <>{errors.autoDisburseStatus.message}</>) || (formBackendError.autoDisburseStatus != "" && <>{formBackendError.autoDisburseStatus}</>)  }</p>
      </div>
      <div>
        <Checkbox {...register("dailyAccrueStatus")} defaultChecked={result?.dailyAccrueStatus} />
        <Label htmlFor="dailyAccrueStatus"> Daily Accrual</Label>
        <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.dailyAccrueStatus && <>{errors.dailyAccrueStatus.message}</>) || (formBackendError.dailyAccrueStatus != "" && <>{formBackendError.dailyAccrueStatus}</>)  }</p>
      </div>
      <hr></hr>
      <div>
        <div id="select">
          <div className="mb-2 block"> <Label htmlFor="feeType" value="Fee Type" /></div>
          <Select  {...register("feeType")} id="feeType" required={true} value={result?.feeType} onChange={(e)=>{ setResult({...result, feeType: e.target.value }) }}>
            <option value={"default"}> Default </option>
            <option value={"tier_by_limit"}> Tiering Berdasarkan Limit/Salary </option>
            <option value={"tier_by_transaction"}> Tiering Berdasarkan Transaksi </option>
          </Select>
        </div>
        <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.feeType && <>{errors.feeType.message}</>) || (formBackendError.feeType != "" && <>{formBackendError.feeType}</>)  }</p>
      </div>

      <LimitBasedFeeModal state={limitBasedFeeModal} setState={setLimitBasedFeeModal} ></LimitBasedFeeModal>
      <TransactionBasedFeeModal state={transactionBasedFeeModal} setState={setTransactionBasedFeeModal} ></TransactionBasedFeeModal>

      {
        result?.feeType == "default" ?
        <>
        <div>
          <div className="mb-2 block"> <Label htmlFor="defaultFixedFee" value="Fixed Fee"/></div>
          <TextInput {...register("defaultFixedFee")} type="number" id="defaultFixedFee"  value={result?.defaultFixedFee} onWheel={(e) => e.currentTarget.blur()} onChange={(e)=>{ setResult({...result, defaultFixedFee: Number(e.target.value) }) }} />
          <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.defaultFixedFee && <>{errors.defaultFixedFee.message}</>) || (formBackendError.defaultFixedFee != "" && <>{formBackendError.defaultFixedFee}</>)  }</p>
        </div>
        <div>
          <div className="mb-2 block"> <Label htmlFor="defaultPercentageFee" value="Percentage Fee (Dalam %)"/></div>
          <TextInput {...register("defaultPercentageFee")} type="number" id="defaultPercentageFee"  value={result?.defaultPercentageFee} onWheel={(e) => e.currentTarget.blur()} onChange={(e)=>{ setResult({...result, defaultPercentageFee: Number(e.target.value) }) }} />
          <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.defaultPercentageFee && <>{errors.defaultPercentageFee.message}</>) || (formBackendError.defaultPercentageFee != "" && <>{formBackendError.defaultPercentageFee}</>)  }</p>
        </div>
        </>
        :
        result?.feeType == "tier_by_limit" ?
        <>
        <Button color="success" onClick={ () => setLimitBasedFeeModal({
          isOpen: "open",
          title: "Tambah",
          onSubmit: (data) => {
            setLimitBasedFee([...limitBasedFee, data])
            setLimitBasedFeeModal({ ...limitBasedFeeModal, isOpen: "close" })
          },
        })}>Tambah Aturan Fee By Limit</Button>

        <Table striped={true}>
          <Table.Head>
            <Table.HeadCell> Amount From </Table.HeadCell>
            <Table.HeadCell> Amount To </Table.HeadCell>
            <Table.HeadCell> Fixed Fee </Table.HeadCell>
            <Table.HeadCell> Percentage Fee </Table.HeadCell>
            <Table.HeadCell> Action </Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {limitBasedFee.map( (lbf, index)=> {
              return <Table.Row key={"lbf-"+index.toString()} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <Table.Cell className="whitespace-nowrap">{lbf.amountFrom}</Table.Cell>
                <Table.Cell className="whitespace-nowrap">{lbf.amountTo}</Table.Cell>
                <Table.Cell className="whitespace-nowrap">{lbf.fixedFee}</Table.Cell>
                <Table.Cell className="whitespace-nowrap">{lbf.percentageFee}</Table.Cell>
                <Table.Cell className="flex items-center space-x-3">
                  <Button color="success" size="xs" onClick={ () => setLimitBasedFeeModal({
                    isOpen: "open",
                    title: "Edit",
                    initialData: lbf,
                    onSubmit: (data) => {
                      limitBasedFee[index] = data
                      setLimitBasedFee(limitBasedFee)
                      setResult({...result, limitBasedFee: limitBasedFee})
                      setLimitBasedFeeModal({ ...limitBasedFeeModal, isOpen: "close" })
                    }
                  })}>Edit</Button>
                  <Button color="failure" size="xs" onClick={()=>{
                    limitBasedFee.splice(index, 1)
                    setLimitBasedFee(limitBasedFee)
                    setResult({...result, limitBasedFee: limitBasedFee})
                  }}>Delete</Button>
                </Table.Cell>
              </Table.Row>
            })}
          </Table.Body>
        </Table>
        </>
        :
        result?.feeType == "tier_by_transaction" ?
        <>
        <div>
          <Button color="success" onClick={ () => setTransactionBasedFeeModal({
            isOpen: "open",
            title: "Tambah",
            onSubmit: (data) => {
              setTransactionBasedFee([...transactionBasedFee, data])
              setTransactionBasedFeeModal({ ...transactionBasedFeeModal, isOpen: "close" })
            },
          })}>Tambah Aturan Fee By Transaction</Button>
        </div>
        <Table striped={true}>
          <Table.Head>
            <Table.HeadCell> Amount From </Table.HeadCell>
            <Table.HeadCell> Amount To </Table.HeadCell>
            <Table.HeadCell> Fixed Fee </Table.HeadCell>
            <Table.HeadCell> Percentage Fee </Table.HeadCell>
            <Table.HeadCell> Action </Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {transactionBasedFee.map( (tbf, index)=> {
              return <Table.Row key={"tbf-"+index.toString()} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <Table.Cell className="whitespace-nowrap">{tbf.amountFrom}</Table.Cell>
                <Table.Cell className="whitespace-nowrap">{tbf.amountTo}</Table.Cell>
                <Table.Cell className="whitespace-nowrap">{tbf.fixedFee}</Table.Cell>
                <Table.Cell className="whitespace-nowrap">{tbf.percentageFee}</Table.Cell>
                <Table.Cell className="flex items-center space-x-3">
                <Button color="success" size="xs" onClick={ () => setTransactionBasedFeeModal({
                    isOpen: "open",
                    title: "Edit",
                    initialData: tbf,
                    onSubmit: (data) => {
                      transactionBasedFee[index] = data
                      setTransactionBasedFee(transactionBasedFee)
                      setResult({...result, transactionBasedFees: transactionBasedFee})
                      setTransactionBasedFeeModal({ ...transactionBasedFeeModal, isOpen: "close" })
                    }
                  })}>Edit</Button>
                  <Button color="failure" size="xs" onClick={()=>{
                    transactionBasedFee.splice(index, 1)
                    setTransactionBasedFee(transactionBasedFee)
                    setResult({...result, transactionBasedFees: transactionBasedFee})
                  }}>Delete</Button>

                </Table.Cell>
              </Table.Row>
            })}
          </Table.Body>
        </Table>
        </>
        :
        null
      }

      <div>
        <Button type="submit" disabled={ updating == true}>
          { updating ? <div className="mr-3"> <Spinner size="sm" light={true} /></div> : null }
          Update
        </Button>
      </div>
    </form>

  </>
};

export default CompanyDetailEwa;


// Limit Based Fee Modal Section

export type LimitBasedFeeState = {
  isOpen: "open" | "close"
  title: string
  onSubmit: (data: LimitBasedFee) => void
  initialData?: LimitBasedFee
}

type Props = {
  state: LimitBasedFeeState
  setState: React.Dispatch<React.SetStateAction<LimitBasedFeeState>>
}

const defaultLimitBasedFee: LimitBasedFee = { id: 0, amountFrom: 0, amountTo: 999999999, fixedFee: 35000, percentageFee: 0}

const LimitBasedFeeModal = (props: Props) => {
  const [lbf, setLbf] = useState<LimitBasedFee>(props.state.initialData ? props.state.initialData : defaultLimitBasedFee)
  useEffect(() => {
      if(props.state.isOpen === "open") {
        setLbf(props.state.initialData ? props.state.initialData : defaultLimitBasedFee)
      }
  }, [props.state])

  const ValidationSchema = yup.object().shape({
      amountFrom: yup.number().required(),
      amountTo: yup.number().required(),
      fixedFee: yup.number(),
      percentageFee: yup.number(),
  })
  const { register, handleSubmit, formState: { errors  }, reset } = useForm<LimitBasedFee>({ mode: 'onChange', resolver: yupResolver(ValidationSchema) });

  const onSubmitHandler = async (data: LimitBasedFee) => {
    const id = props.state.initialData ? props.state.initialData.id : 0
    props.state.onSubmit({
      id: id,
      amountFrom: data.amountFrom,
      amountTo: data.amountTo,
      fixedFee: data.fixedFee,
      percentageFee: data.percentageFee,
    })
  };

  return <>
      <Modal
          show={props.state.isOpen === "open"}
          onClose={ ()=> {props.setState({...props.state, isOpen:"close"})}}
      >
          <Modal.Header>{props.state.title}</Modal.Header>
          <Modal.Body>
            <form key="lbf-form" className="flex flex-col gap-4">
              <div>
                <div className="mb-2 block"> <Label htmlFor="amountFrom" value="Amount From"/></div>
                <TextInput  {...register("amountFrom")} type="number" id="amountFrom"  value={lbf.amountFrom} onWheel={(e) => e.currentTarget.blur()} onChange={(e)=>{ setLbf({...lbf, amountFrom: Number(e.target.value) }) }} />
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.amountFrom && <>{errors.amountFrom.message}</>)  }</p>
              </div>
              <div>
                <div className="mb-2 block"> <Label htmlFor="amountTo" value="Amount To"/></div>
                <TextInput {...register("amountTo")} type="number" id="amountTo"  value={lbf.amountTo} onWheel={(e) => e.currentTarget.blur()} onChange={(e)=>{ setLbf({...lbf, amountTo: Number(e.target.value) }) }} />
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.amountTo && <>{errors.amountTo.message}</>)  }</p>
              </div>
              <div>
                <div className="mb-2 block"> <Label htmlFor="fixedFee" value="Fixed Fee"/></div>
                <TextInput {...register("fixedFee")} type="number" id="fixedFee"  value={lbf.fixedFee} onWheel={(e) => e.currentTarget.blur()} onChange={(e)=>{ setLbf({...lbf, fixedFee: Number(e.target.value) }) }} />
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.fixedFee && <>{errors.fixedFee.message}</>)  }</p>
              </div>
              <div>
                <div className="mb-2 block"> <Label htmlFor="percentageFee" value="Percentage Fee (Dalam %)"/></div>
                <TextInput {...register("percentageFee")} type="number" id="percentageFee"  value={lbf.percentageFee} onWheel={(e) => e.currentTarget.blur()} onChange={(e)=>{ setLbf({...lbf, percentageFee: Number(e.target.value) }) }} />
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.percentageFee && <>{errors.percentageFee.message}</>)  }</p>
              </div>
              <Button onClick={handleSubmit(onSubmitHandler)}>
                Submit
              </Button>
            </form>
          </Modal.Body>
      </Modal>
  </>
}



// Transaction Based Fee Modal Section

export type TransactionBasedFeeState = {
  isOpen: "open" | "close"
  title: string
  onSubmit: (data: TransactionBasedFee) => void
  initialData?: TransactionBasedFee
}

type TransactionBasedFeeProps = {
  state: TransactionBasedFeeState
  setState: React.Dispatch<React.SetStateAction<TransactionBasedFeeState>>
}

const defaultTransactionBasedFee: TransactionBasedFee = { id: 0, amountFrom: 0, amountTo: 999999999, fixedFee: 35000, percentageFee: 0}

const TransactionBasedFeeModal = (props: TransactionBasedFeeProps) => {
  const [lbf, setLbf] = useState<TransactionBasedFee>(props.state.initialData ? props.state.initialData : defaultTransactionBasedFee)
  useEffect(() => {
      if(props.state.isOpen === "open") {
        setLbf(props.state.initialData ? props.state.initialData : defaultTransactionBasedFee)
      }
  }, [props.state])

  const ValidationSchema = yup.object().shape({
      amountFrom: yup.number().required(),
      amountTo: yup.number().required(),
      fixedFee: yup.number(),
      percentageFee: yup.number(),
  })
  const { register, handleSubmit, formState: { errors  }, reset } = useForm<TransactionBasedFee>({ mode: 'onChange', resolver: yupResolver(ValidationSchema) });

  const onSubmitHandler = async (data: TransactionBasedFee) => {
    const id = props.state.initialData ? props.state.initialData.id : 0
    props.state.onSubmit({
      id: id,
      amountFrom: data.amountFrom,
      amountTo: data.amountTo,
      fixedFee: data.fixedFee,
      percentageFee: data.percentageFee,
    })
  };

  return <>
      <Modal
          show={props.state.isOpen === "open"}
          onClose={ ()=> {props.setState({...props.state, isOpen:"close"})}}
      >
          <Modal.Header>{props.state.title}</Modal.Header>
          <Modal.Body>
            <form key="tbf-form" className="flex flex-col gap-4">
              <div>
                <div className="mb-2 block"> <Label htmlFor="amountFrom" value="Amount From"/></div>
                <TextInput  {...register("amountFrom")} type="number" id="amountFrom"  value={lbf.amountFrom} onWheel={(e) => e.currentTarget.blur()} onChange={(e)=>{ setLbf({...lbf, amountFrom: Number(e.target.value) }) }} />
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.amountFrom && <>{errors.amountFrom.message}</>)  }</p>
              </div>
              <div>
                <div className="mb-2 block"> <Label htmlFor="amountTo" value="Amount To"/></div>
                <TextInput {...register("amountTo")} type="number" id="amountTo"  value={lbf.amountTo} onWheel={(e) => e.currentTarget.blur()} onChange={(e)=>{ setLbf({...lbf, amountTo: Number(e.target.value) }) }} />
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.amountTo && <>{errors.amountTo.message}</>)  }</p>
              </div>
              <div>
                <div className="mb-2 block"> <Label htmlFor="fixedFee" value="Fixed Fee"/></div>
                <TextInput {...register("fixedFee")} type="number" id="fixedFee"  value={lbf.fixedFee} onWheel={(e) => e.currentTarget.blur()} onChange={(e)=>{ setLbf({...lbf, fixedFee: Number(e.target.value) }) }} />
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.fixedFee && <>{errors.fixedFee.message}</>)  }</p>
              </div>
              <div>
                <div className="mb-2 block"> <Label htmlFor="percentageFee" value="Percentage Fee (Dalam %)"/></div>
                <TextInput {...register("percentageFee")} type="number" id="percentageFee"  value={lbf.percentageFee} onWheel={(e) => e.currentTarget.blur()} onChange={(e)=>{ setLbf({...lbf, percentageFee: Number(e.target.value) }) }} />
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.percentageFee && <>{errors.percentageFee.message}</>)  }</p>
              </div>
              <Button onClick={handleSubmit(onSubmitHandler)}>
                Submit
              </Button>
            </form>
          </Modal.Body>
      </Modal>
  </>
}
