export enum HolidayType {
  NATIONAL_HOLIDAY = "national_holiday",
  JOINT_LEAVE = "joint_leave",
  COMPANY_EVENT = "company_event",
  OTHER = "other"
}

export interface HolidayTemplate {
  id: number;
  name: string;
  dateAt: Date;
  holidayType: HolidayType;
}

export interface HolidayTemplates{
  holidays: HolidayTemplate[]
}