import { AuthToken } from "_interfaces/usertoken.interfaces";
import request from "_network/request";
import { ApiSuccessResponse } from "_network/response";

const ValidateToken = (authToken: string): Promise<ApiSuccessResponse<AuthToken>> => {
    return request({
      url: `usertoken/validate-token`,
      method: 'POST',
      data: {
        authToken: authToken
      },
    });
};
  
export const UserTokenServices = {
    ValidateToken,
};