import { useEffect, useState } from "react";
import { useAppDispatch } from "../../redux/hook";
import { hideLoading, setHeader, showLoading } from "../../redux/global/global.action";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Pagination, Table, TextInput } from "flowbite-react";
import { SuperadminServices } from "../../_services/superadmin.services";
import { Companies } from "../../_interfaces";
import { ApiErrorResponse, UnknownError } from "../../_network/response";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';


const CompanyList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(setHeader({ title: "Company List" }));
    dispatch(hideLoading())
  }, []);
  

  const [param, setParam] = useState({keyword:"", currentPage: 1})
  const [searchKeyword, setSearchKeyword] = useState("");

  const [result, error] = useFetch(param)
  
  return (
    <>
      {/* https://flowbite.com/docs/components/tables/ */}
      <div className="p-4 pb-4 bg-white dark:bg-gray-900">
          <label htmlFor="table-search" className="sr-only">Search</label>
          <div className="relative mt-1 inline-block">
              <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                  <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
              </div>
              <input 
                type="text" 
                id="table-search" 
                className=" p-2 pl-10 w-80 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Masukkan keyword" 
                onChange={(e)=> setSearchKeyword(e.target.value)} 
              />
          </div>
          <div className="ml-2 inline-block">
            <Button color="success" size="sm" onClick={()=>{ setParam({...param, keyword: searchKeyword}) }}>Apply</Button>
          </div>
      </div>
      <Table striped={true}>
        <Table.Head>
          <Table.HeadCell> No </Table.HeadCell>
          <Table.HeadCell> Id </Table.HeadCell>
          <Table.HeadCell> Name </Table.HeadCell>
          <Table.HeadCell> Action </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {
            (result !== undefined && result?.companies.length <= 0)  ?
            <Table.Cell colSpan={4}> Tidak ada data perusahaan</Table.Cell>
            :
            result?.companies.map( (c, i) => {
              return <Table.Row key={"company-"+c.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {(i+1).toString()}
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap"> {c.id} </Table.Cell>
                <Table.Cell className="whitespace-nowrap"> {c.name} </Table.Cell>
                <Table.Cell className="flex items-center space-x-3">
                  <Button color="success" size="xs" onClick={()=>{ navigate(`/company/${c.id}`) }}>View</Button>
                </Table.Cell>
              </Table.Row>
            })
          }
        </Table.Body>
      </Table>
      <Pagination
        currentPage={param.currentPage}
        totalPages={result ? result.pagination.lastPage : param.currentPage}
        onPageChange={ (page:number)=> {
          setParam({...param, currentPage: page})
        }}
      />
    </>
  );
};

export default CompanyList;


export function useFetch(param :{keyword:string, currentPage:number}) :[Companies, ApiErrorResponse | undefined] {
  const [result, setResult] = useState<Companies>({
    companies:[], 
    pagination:{
      currentPage: 1, 
      lastPage: 1, 
      from: 1, 
      to: 50, 
      firstPage: 1, 
      perPage: 50, 
      total: 0, 
  }});
  const [error, setError] = useState<ApiErrorResponse>();
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    async function fetch(_keyword:string, _currentPage :number) {
      try {
        dispatch(showLoading())
        var res = await SuperadminServices.getCompanyByPagination(_keyword, _currentPage, 50);
        setResult(res.data);
      } catch (error) {
        if(error as ApiErrorResponse){
          setError(error as ApiErrorResponse);
          toast.error((error as ApiErrorResponse).message)
        } else {
          console.log("Unknown error:",error);
          setError(UnknownError(""));
          toast.error("Internal Error")
        }
      } finally {
        dispatch(hideLoading())
      }
    }

    fetch(param.keyword, param.currentPage)
  }, [param]);
  
  return [result, error];
}
