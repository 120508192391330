import { LoginPhoneNumberReq } from "../_interfaces";
import request from "../_network/request";

const login = (payload: LoginPhoneNumberReq) => {
  return request({
    url: `auth/login/superadmin/phone`,
    method: "POST",
    data: payload,
  });
};

export const AuthServices = {
  login,
};
