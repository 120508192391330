import moment from 'moment';
import {
  Companies,
  Company,
  CompanyEwa,
  CompanyPeriod,
  CompanyTax,
  EwaTransactionDayToDay,
  EwaTransactionSummary,
  FlipBalance,
  IakBalance,
  ScriptHistories,
  UpdateCompanyPeriodSettingReq,
  WageRequestDetails,
} from '../_interfaces';
import request from '../_network/request';
import { ApiSuccessResponse } from '../_network/response';
import { endpoint } from '_network/endpoint';
// const authUrl = "/employee/profile";

// GetEwaTransactionSummary
const getEwaTransactionSummary = (year: number, month: number): Promise<ApiSuccessResponse<EwaTransactionSummary>> => {
  return request({
    url: `${endpoint('superadmin')}/summary/ewa/transaction?year=${year}&month=${month}`,
    method: 'GET',
  });
};

// GetFlipBalance
const getFlipBalance = (): Promise<ApiSuccessResponse<FlipBalance>> => {
  return request({
    url: `${endpoint('superadmin')}/summary/flip/balance`,
    method: 'GET',
  });
};

const getIakBalance = (): Promise<ApiSuccessResponse<IakBalance>> => {
  return request({
    url: `${endpoint('superadmin')}/summary/iak/balance`,
    method: 'GET',
  });
};

// GetFlipBalance
const getWageRequestByDateRange = (
  dateFrom: Date,
  dateTo: Date,
  page: number,
  size: number,
): Promise<ApiSuccessResponse<WageRequestDetails>> => {
  const dateFromMoment = moment(dateFrom).format('YYYY-MM-DD');
  const dateToMoment = moment(dateTo).format('YYYY-MM-DD');

  return request({
    url: `${endpoint('superadmin')}/ewa/transactions/${dateFromMoment}/${dateToMoment}?page=${page}&size=${size}`,
    method: 'GET',
  });
};

// GetByPagination
const getCompanyByPagination = (
  keyword: string,
  page: number,
  size: number,
): Promise<ApiSuccessResponse<Companies>> => {
  return request({
    url: `${endpoint('superadmin')}/company?keyword=${keyword}&page=${page}&size=${size}`,
    method: 'GET',
  });
};

// CompanyApi.GetByCode
const getCompanyByCode = (company_id: string): Promise<ApiSuccessResponse<Company>> => {
  return request({
    url: `${endpoint('superadmin')}/company/${company_id}`,
    method: 'GET',
  });
};

// CompanyApi.GetTaxDataByCode
const getCompanyTaxByCode = (company_id: string): Promise<ApiSuccessResponse<CompanyTax>> => {
  return request({
    url: `${endpoint('superadmin')}/company/${company_id}/tax`,
    method: 'GET',
  });
};

// CompanyApi.UpdateCompanyTax
const updateCompany = (payload: Company): Promise<ApiSuccessResponse<void>> => {
  return request({
    url: `${endpoint('superadmin')}/company/${payload.id}`,
    method: 'PUT',
    data: payload,
  });
};

// CompanyApi.UpdateCompanyTax
const updateCompanyTax = (payload: CompanyTax): Promise<ApiSuccessResponse<void>> => {
  return request({
    url: `${endpoint('superadmin')}/company/${payload.companyId}/tax`,
    method: 'PUT',
    data: payload,
  });
};

// CompanyApi.GetCompanyEwa
const getCompanyEwa = (company_id: string): Promise<ApiSuccessResponse<CompanyEwa>> => {
  return request({
    url: `${endpoint('superadmin')}/company/${company_id}/ewa`,
    method: 'GET',
  });
};

// CompanyApi.UpdateCompanyEwa
const updateCompanyEwa = (payload: CompanyEwa): Promise<ApiSuccessResponse<void>> => {
  return request({
    url: `${endpoint('superadmin')}/company/${payload.companyId}/ewa`,
    method: 'PUT',
    data: payload,
  });
};

// CompanyApi.GetCompanyPeriod
const getCompanyPeriod = (company_id: string): Promise<ApiSuccessResponse<CompanyPeriod>> => {
  return request({
    url: `${endpoint('superadmin')}/company/${company_id}/period`,
    method: 'GET',
  });
};

// CompanyApi.UpdateCompanyPeriodSetting
const updateCompanyPeriodSetting = (payload: UpdateCompanyPeriodSettingReq): Promise<ApiSuccessResponse<void>> => {
  return request({
    url: `${endpoint('superadmin')}/company/${payload.companyId}/period-setting`,
    method: 'PUT',
    data: payload,
  });
};

// CompanyApi.GetEwaTransactionDayToDay
const getEwaTransactionDayToDay = (
  year: number,
  month: number,
): Promise<ApiSuccessResponse<EwaTransactionDayToDay>> => {
  return request({
    url: `${endpoint('superadmin')}/summary/ewa/transaction/day-to-day?year=${year}&month=${month}`,
    method: 'GET',
  });
};









const Script_GetByPagination = (
  category: "CREATE_WAGE_REQUEST"|"UPDATE_SALARY"|"UPDATE_EMPLOYEE_EWA"|"UPLOAD_ALLOWANCE",
  page: number,
  size: number,
): Promise<ApiSuccessResponse<ScriptHistories>> => {
  return request({
    // url: `http://localhost:30000/script/history?category=${category}&page=${page}&size=${size}`,
    url: `${endpoint('superadmin')}/script/history?category=${category}&page=${page}&size=${size}`,
    method: 'GET',
  });
};

const Script_GetOutput = (id: number): Promise<ApiSuccessResponse<{output: string}>> => {
  return request({
    // url: `http://localhost:30000/script/history/${id}`,
    url: `${endpoint('superadmin')}/script/history/${id}`,
    method: 'GET',
  });
};

const Script_CreateWageRequest = (
  environment: "production"|"staging",
  file: string,
): Promise<ApiSuccessResponse<void>> => {
  return request({
    // url: `http://localhost:30000/script/create-wage-request`,
    url: `${endpoint('superadmin')}/script/create-wage-request`,
    method: 'POST',
    data: {
      environment: environment,
      file: file,
    },
  });
};

const Script_UpdateSalary = (
  environment: "production"|"staging",
  file: string,
): Promise<ApiSuccessResponse<void>> => {
  return request({
    // url: `http://localhost:30000/script/update-salary`,
    url: `${endpoint('superadmin')}/script/update-salary`,
    method: 'POST',
    data: {
      environment: environment,
      file: file,
    },
  });
};


const Script_UpdateEmployeeEwa = (
  environment: "production"|"staging",
  companyId: string,
  file: string,
): Promise<ApiSuccessResponse<void>> => {
  return request({
    // url: `http://localhost:30000/script/update-salary`,
    url: `${endpoint('superadmin')}/script/update-employee-ewa-by-csv`,
    method: 'POST',
    data: {
      environment: environment,
      companyId:companyId,
      file: file,
    },
  });
};

const Script_UploadAllowance = (
  environment: "production"|"staging",
  companyId: string,
  date:Date,
  file: string,
): Promise<ApiSuccessResponse<void>> => {
  return request({
    // url: `http://localhost:30000/script/update-salary`,
    url: `${endpoint('superadmin')}/script/update-allowance`,
    method: 'POST',
    data: {
      environment: environment,
      companyId:companyId,
      file: file,
      date: moment(date).format("YYYY-MM-DD")
    },
  });
};

const Script_ResetPin = (
  companyId: string,
  employeeNumber:string,
): Promise<ApiSuccessResponse<void>> => {
  return request({
    // url: `http://localhost:30000/script/update-salary`,
    url: `superadmin/script/reset-pin`,
    method: 'POST',
    data: {
      companyId:companyId,
      employeeNumber: employeeNumber,
    },
  });
};



export const SuperadminServices = {
  getEwaTransactionSummary,
  getFlipBalance,
  getIakBalance,
  getWageRequestByDateRange,
  getCompanyByPagination,
  getCompanyByCode,
  getCompanyTaxByCode,
  updateCompanyTax,
  updateCompany,
  getCompanyEwa,
  updateCompanyEwa,
  getCompanyPeriod,
  updateCompanyPeriodSetting,
  getEwaTransactionDayToDay,
  Script: {
    Script_GetByPagination,
    Script_CreateWageRequest,
    Script_UpdateSalary,
    Script_UpdateEmployeeEwa,
    Script_UploadAllowance,
    Script_ResetPin,
    Script_GetOutput,
  },
};
