import Spinner from "../spinner";

// export * from "./summary-card";

type Props = {
    title: string
    value :string
    is_loading: boolean
    icon :any
    icon_bg_color :string
    subtitle :any
}

const SummaryCard = (props: Props = {
    title: "",
    value: "",
    is_loading: false,
    icon: null,
    icon_bg_color: "bg-red-500",
    subtitle: null,
  }) => {
    return  <>
        <div className="mt-4 w-full lg:w-6/12 xl:w-4/12 px-5 mb-4">
            <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-3 xl:mb-0 shadow-lg">
                <div className="flex-auto p-4">
                <div className="flex flex-wrap">
                    <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                    <h5 className="text-blueGray-400 uppercase font-bold text-xs"> {props.title}</h5>
                    <span className="font-semibold text-xl text-blueGray-700">{props.is_loading ? <Spinner></Spinner> :props.value }</span>
                    </div>
                    <div className="relative w-auto pl-4 flex-initial">
                    <div className={"text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full " + props.icon_bg_color}>
                        {props.icon}
                    </div>
                    </div>
                </div>
                <p className="text-sm text-blueGray-400 mt-4">
                    
                    {/* <span className="text-emerald-500 mr-2">{}</span> */}
                    <span className="whitespace-nowrap"> {props.subtitle} </span>
                </p>
                </div>
            </div>
        </div>
        </>
    
}

export default SummaryCard;
