import { useEffect, useState } from "react";
import { EwaTransactionSummary } from "../../_interfaces";
import { ApiErrorResponse, UnknownError } from "../../_network/response";
import { SuperadminServices } from "../../_services";

export function useSuperadminGetEwaTransaction(year :number, month :number) :[EwaTransactionSummary | undefined, ApiErrorResponse | undefined, boolean] {
  const [result, setResult] = useState<EwaTransactionSummary>();
  const [error, setError] = useState<ApiErrorResponse>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetch(_year :number, _month :number) {
      try {
        setResult(undefined)
        setError(undefined)
        setLoading(true);

        var res = await SuperadminServices.getEwaTransactionSummary(_year, _month);
        setResult(res.data);
      } catch (error) {
        if(error as ApiErrorResponse){
          setError(error as ApiErrorResponse);
        }  else {
          console.log("Unknown error:",error);
          setError(UnknownError(""));
        }
      } finally {
        setLoading(false)
      }
    }

    fetch(year, month)
  }, [month]);

  return [result, error, loading];
}
