import {
  Badge,
  Button,
  Label,
  Modal,
  Spinner,
  Table,
  Textarea,
  TextInput,
  Tooltip,
} from "flowbite-react";
import useDigitalProduct from "pages/digital-product/useDigitalProduct";
import { useEffect, useState } from "react";
import { FiTrash } from "react-icons/fi";
import { BiLinkAlt } from "react-icons/bi";
import { toast } from "react-toastify";
import { showAlert } from "redux/global/global.action";
import { useAppDispatch } from "redux/hook";
import { DigitalProductValue } from "_interfaces/digitalProduct.interface";
import { ApiErrorResponse } from "_network/response";
import { DigitalProductApi } from "_services/digitalProduct.services";
import moment from "moment";

export const VoucherModal = ({
  show,
  code,
  onClose,
}: {
  show: boolean;
  code: string;
  onClose: (show: boolean) => void;
}) => {
  const { FindDigitalProduct, digitalProductDetail } = useDigitalProduct();
  const [loading, setLoading] = useState<boolean>(false);
  const [modalConfirm, setModalConfirm] = useState<{
    id: number;
    show: boolean;
  }>({
    id: 0,
    show: false,
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (code) FindDigitalProduct(code);
  }, [code]);

  const closeModal = () => {
    setModalConfirm({
      id: 0,
      show: false,
    });
  };

  const DeleteVoucher = async (id: number) => {
    setLoading(true);
    try {
      await DigitalProductApi.DeleteDigitalVoucher(id);
      setLoading(false);
      toast.success("Delete data success");
      FindDigitalProduct(code);
      setModalConfirm({
        show: false,
        id: 0,
      });
    } catch (error) {
      toast.error((error as ApiErrorResponse).message);
      setLoading(false);
    }
  };

  const checkStatus = (expiredAt: string, purchasedAt: string) => {

    if (purchasedAt !== "") {
      return 'Sold'
    } 

    if ( expiredAt !== "" && moment(expiredAt).isBefore(new Date())) {
      return 'Expired'
    } 

    return 'Available'
  }

  return (
    <>
      <Modal show={show} onClose={() => onClose(false)}>
        <Modal.Header>Voucher Detail</Modal.Header>
        <Modal.Body>
          <div className="space-y-6 mb-5">
            <table className="w-full">
              <tr>
                <td className="py-2">
                  <Label>Group Name</Label>
                </td>
                <td className="py-2">
                  <TextInput
                    type={"text"}
                    value={digitalProductDetail?.description}
                    disabled={true}
                  />
                </td>
              </tr>
              <tr>
                <td className="py-2">
                  <Label>Product Code</Label>
                </td>
                <td className="py-2">
                  <TextInput
                    type={"text"}
                    value={digitalProductDetail?.code}
                    disabled={true}
                  />
                </td>
              </tr>
              <tr>
                <td className="py-2">
                  <Label>Name</Label>
                </td>
                <td className="py-2">
                  <TextInput
                    type={"text"}
                    value={digitalProductDetail?.name}
                    disabled={true}
                  />
                </td>
              </tr>
            </table>
            <div className="mb-4">
              <Table>
                <Table.Head className="!bg-primary !text-white">
                  <Table.HeadCell>Voucher Code</Table.HeadCell>
                  <Table.HeadCell>Expired At</Table.HeadCell>
                  <Table.HeadCell>Status</Table.HeadCell>
                  <Table.HeadCell>Action</Table.HeadCell>
                </Table.Head>
                <Table.Body>
                  {digitalProductDetail?.digitalProductValues?.map((dp, i) => (
                    <Table.Row key={i}>
                      <Table.Cell>{dp?.sn}</Table.Cell>
                      <Table.Cell>
                        {dp?.expiredAt !== ""
                          ? moment(dp?.expiredAt).format("DD MMM YYYY HH:mm")
                          : "-"}
                      </Table.Cell>
                      <Table.Cell>
                        <Badge
                          color={ checkStatus(dp?.expiredAt, dp?.purchasedAt) === "Available" ? "info" : "failure"  }
                          >
                          {checkStatus(dp?.expiredAt, dp?.purchasedAt)}
                        </Badge>
                      </Table.Cell>
                      <Table.Cell className="flex gap-2">
                        <Button
                          disabled={checkStatus(dp?.expiredAt, dp?.purchasedAt) !== "Available" }
                          color={"failure"}
                          size="xs"
                          onClick={() => {
                            setModalConfirm({
                              id: dp.id,
                              show: true,
                            });
                          }}
                        >
                          <FiTrash />
                        </Button>
                        <Tooltip
                          content="Copy URL Voucher"
                          style="dark"
                          animation="duration-500"
                        >
                          <Button
                            disabled={
                              dp?.url === "" ||
                              dp?.url === undefined || 
                              checkStatus(dp?.expiredAt, dp?.purchasedAt) !== "Available"
                            }
                            color={"gray"}
                            size="xs"
                            onClick={() => {
                              navigator.clipboard.writeText(dp?.url);
                              toast.success("URL already copy to clipboard");
                            }}
                          >
                            <BiLinkAlt />
                          </Button>
                        </Tooltip>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                  {digitalProductDetail?.digitalProductValues?.length === 0 && (
                    <Table.Cell className="text-center" colSpan={12}>
                      No datas
                    </Table.Cell>
                  )}
                </Table.Body>
              </Table>
            </div>
          </div>
          <div className="grid grid-cols-4 text-center">
            <div>
              <Label>Total Quota</Label>
              <p className="text-sm">
                {digitalProductDetail?.digitalProductValues?.length ?? 0 }
              </p>
            </div>
            <div>
              <Label>Used Quota</Label>
              <p className="text-sm">
                {
                  digitalProductDetail?.digitalProductValues?.filter((x) => checkStatus(x.expiredAt, x.purchasedAt) === "Sold").length ?? 0
                }
              </p>
            </div>
            <div>
              <Label>Expired Voucher</Label>
              <p className="text-sm">
                {
                  digitalProductDetail?.digitalProductValues?.filter((x) => checkStatus(x.expiredAt, x.purchasedAt) === "Expired").length ?? 0
                }
              </p>
            </div>
            <div>
              <Label>Remaining Quota</Label>
              <p className="text-sm">
                {
                  digitalProductDetail?.digitalProductValues?.filter((x) => checkStatus(x.expiredAt, x.purchasedAt) === "Available").length ?? 0
                }
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={modalConfirm?.show}
        size="md"
        popup={true}
        onClose={() => closeModal()}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Are you sure you want to delete this voucher?
            </h3>
            <div className="flex justify-center gap-4">
              <Button
                disabled={loading}
                color="failure"
                onClick={() => {
                  DeleteVoucher(modalConfirm?.id);
                }}
              >
                {loading && (
                  <div className="mr-2">
                    <Spinner />
                  </div>
                )}
                Yes, I'm sure
              </Button>
              <Button color="gray" onClick={() => closeModal()}>
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
