import { ApiErrorResponse, ApiSuccessResponse } from '_network/response';
import { useState } from 'react';
import { toast } from 'react-toastify';

export type Resolve<T> = (data: T) => void;
export type Reject = (error: ApiErrorResponse) => void;
export interface State<T> {
  data?: T;
  error?: Error;
  loading: boolean;
  request: Function;
  call: Function;
}

export interface FuncState<F extends ServiceFunc> {
  data?: UnwrappedResponse<ReturnType<F>>;
  error?: Error;
  loading: boolean;
  request: (...args: Parameters<F>) => void;
  call: (...args: Parameters<F>) => Promise<UnwrappedResponse<ReturnType<F>> | ApiErrorResponse | string>;
}

export type ServiceFunc<T = unknown> = (...args: any[]) => Promise<ApiSuccessResponse<T>>
export type UnwrappedResponse<T> = T extends Promise<ApiSuccessResponse<infer U>> ? U : never;

export function useFetch<T = unknown>(fn: Function, resolve?: Resolve<T>, reject?: Reject): State<T> {
  const [data, setData] = useState<T>();
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState<boolean>(false);

  if (reject === undefined) {
    reject = (err: ApiErrorResponse): void => {
      toast.error(err.message);
    };
  }

  const request = async (...args: any[]) => {
    // Reset the value before request
    setData(undefined);
    setError(undefined);
    setLoading(true);

    try {
      const response: ApiSuccessResponse<T> = await fn(...args);

      if (response !== undefined) {
        const data: T = response.data as T;
        setData(data);
        resolve !== undefined && resolve(data);
      }
    } catch (err) {
      setError(err as Error);
      reject !== undefined && reject(err as ApiErrorResponse);
    } finally {
      setLoading(false);
    }
  };

  const call = async (...args: any[]): Promise<T | ApiErrorResponse | string> => {
    setData(undefined);
    setError(undefined);
    setLoading(true);

    try {
      const response: ApiSuccessResponse<T> = await fn(...args);

      if (response !== undefined) {
        const data: T = response.data as T;
        setData(data);

        return Promise.resolve(data);
      }
    } catch (err) {
      setError(err as Error);

      return Promise.reject(err as ApiErrorResponse);
    } finally {
      setLoading(false);
    }

    return Promise.reject('Unknown error');
  };

  return {
    data,
    error,
    loading,
    request,
    call,
  };
}

export function useFetchFunc<
  F extends ServiceFunc = ServiceFunc<any>,
  T extends UnwrappedResponse<ReturnType<F>> = UnwrappedResponse<ReturnType<F>>,
>(
  fn: F, 
  resolve?: Resolve<T>, 
  reject?: Reject,
): FuncState<F>{
  const [data, setData] = useState<T|undefined>();
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState<boolean>(false);

  if (reject === undefined) {
    reject = (err: ApiErrorResponse): void => {
      toast.error(err.message);
    };
  }

  const request = async (...args: Parameters<F>) => {
    // Reset the value before request
    setData(undefined);
    setError(undefined);
    setLoading(true);

    try {
      const response  = await fn(...args);

      if (response !== undefined) {
        const data = response.data as T;
        setData(data);
        resolve !== undefined && resolve(data);
      }
    } catch (err) {
      setError(err as Error);
      reject !== undefined && reject(err as ApiErrorResponse);
    } finally {
      setLoading(false);
    }
  };

  const call = async (...args: Parameters<F>): Promise<T | ApiErrorResponse | string> => {
    setData(undefined);
    setError(undefined);
    setLoading(true);

    try {
      const response = await fn(...args);

      if (response !== undefined) {
        const data = response.data as T;
        setData(data);

        return Promise.resolve(data);
      }
    } catch (err) {
      setError(err as Error);

      return Promise.reject(err as ApiErrorResponse);
    } finally {
      setLoading(false);
    }

    return Promise.reject('Unknown error');
  };

  return {
    data,
    error,
    loading,
    request,
    call,
  };
}
