import { Button, Label, Select, Spinner, Tabs, TabsComponent, TextInput } from "flowbite-react";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { hideLoading, setHeader, showLoading } from "../../redux/global/global.action";
import { useAppDispatch } from "../../redux/hook";
import { Company, FormState } from "../../_interfaces";
import { ApiErrorResponse, UnknownError } from "../../_network/response";
import { SuperadminServices } from "../../_services";
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify';

const CompanyDetailProfile = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(
      setHeader({
        title: "Company Detail - Profile",
        hasBack: true,
      })
    );
    dispatch(hideLoading())
  }, []);
  const params = useParams();
  const company_id: string = params.id ? params.id : ""

  // 1. Hook for Getter
  const [result, setResult, error] = useFetch(company_id)
  useEffect(() => { reset() }, [result]); // to reset value for yup form validator

  const [timezone, setTimezone] = useState('Asia/Jakarta');
  useEffect(() => {
    setTimezone(result.timezone);
  }, [result]);

  // 2. Hook for Updater
  const [updating, setUpdating] = useState(false)
  const [formBackendError, setFormBackendError] = useState({
    name: "",
    formalName: "",
    businessField: "",
    timezone: "",
    address: "",
    phoneNumber: "",
    faxNumber: "",
    email: "",
    website: "",
    logo: "",
  })

  const ValidationSchema = yup.object().shape({
    name: yup.string().required(),
    formalName: yup.string(),
    businessField: yup.string(),
    timezone: yup.string().required().is(["Asia/Jakarta", "Asia/Makassar", "Asia/Jayapura"]),
    address: yup.string(),
    phoneNumber: yup.string(),
    faxNumber: yup.string(),
    email: yup.string(),
    website: yup.string(),
    logo: yup.string(),
  })

  const { register, handleSubmit, formState: { errors  }, reset } = useForm<Company>({ mode: 'onSubmit', resolver: yupResolver(ValidationSchema) });
  const onSubmitHandler = async (data: Company) => {
    try {
      setUpdating(true)
      setFormBackendError({ name: "", formalName: "", businessField: "", timezone: "", address: "", phoneNumber: "", faxNumber: "", email: "", website: "", logo: ""})

      data.id = company_id
      await SuperadminServices.updateCompany(data);
      toast.success("Update data sukses")
    } catch (error) {
      if(error as ApiErrorResponse){
        (error as ApiErrorResponse).other_errors.forEach( (e) => {
          switch(e.field){
            case "name": setFormBackendError({...formBackendError, name: e.message} ); break;
            case "formalName": setFormBackendError({...formBackendError, formalName: e.message} ); break;
            case "businessField": setFormBackendError({...formBackendError, businessField: e.message} ); break;
            case "timezone": setFormBackendError({...formBackendError, timezone: e.message} ); break;
            case "address": setFormBackendError({...formBackendError, address: e.message} ); break;
            case "phoneNumber": setFormBackendError({...formBackendError, phoneNumber: e.message} ); break;
            case "faxNumber": setFormBackendError({...formBackendError, faxNumber: e.message} ); break;
            case "email": setFormBackendError({...formBackendError, email: e.message} ); break;
            case "website": setFormBackendError({...formBackendError, website: e.message} ); break;
            case "logo": setFormBackendError({...formBackendError, logo: e.message} ); break;
          }
        })
        toast.error((error as ApiErrorResponse).message)
      } else {
        console.log("Unknown error:",error);
        toast.error("Internal Error")
      }
    } finally {
      setUpdating(false)
    }
  };
  const handleChangeTimezone = (e: any) => {
    setTimezone(e.target.value)
  }

  // https://flowbite.com/docs/components/tabs/
  return <>
    <ul className="flex mb-4 flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
      <li className="mr-2">
        <Link to={"/company/" + params.id} className="inline-block p-4 text-blue-600 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"> Profil </Link>
      </li>
      <li className="mr-2">
        <Link to={"/company/" + params.id + "/tax"} className="inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"> Data Wajib Pajak </Link>
      </li>
      <li className="mr-2">
        <Link to={"/company/" + params.id + "/period"} className="inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"> Periode </Link>
      </li>
      <li className="mr-2">
        <Link to={"/company/" + params.id + "/ewa"} aria-current="page" className="inline-block p-4 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500"> EWA </Link>
      </li>
      <li className="mr-2">
        <Link to={"/company/" + params.id + "/loan"} aria-current="page" className="inline-block p-4 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500"> Pinjaman </Link>
      </li>
    </ul>

    <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmitHandler)}>
      <div>
        <div className="mb-2 block"> <Label htmlFor="name" value="Nama"/></div>
        <TextInput {...register("name")} id="name" defaultValue={result.name}  />
        <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.name && <>{errors.name.message}</>) || (formBackendError.name !== "" && <>{formBackendError.name}</>)  }</p>
      </div>
      <div>
        <div className="mb-2 block"> <Label htmlFor="businessField" value="Bidang Usaha"/></div>
        <TextInput {...register("businessField")} id="businessField"  value={result?.businessField} onChange={(e)=>{ setResult({...result, businessField: e.target.value }) }} />
        <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.businessField && <>{errors.businessField.message}</>) || (formBackendError.businessField !== "" && <>{formBackendError.businessField}</>)  }</p>
      </div>
      <div>
        <div className="mb-2 block"> <Label htmlFor="address" value="Alamat Lengkap*"/></div>
        <TextInput {...register("address")} id="address"  value={result?.address} onChange={(e)=>{ setResult({...result, address: e.target.value }) }} />
        <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.address && <>{errors.address.message}</>) || (formBackendError.address !== "" && <>{formBackendError.address}</>)  }</p>
      </div>
      <div>
        <div id="select">
          <div className="mb-2 block">
            <Label htmlFor="timezone" value="Timezone (ID)*" />
          </div>
          <Select {...register("timezone")} id="timezone" required={true} value={timezone} onChange={handleChangeTimezone}>
            <option value={"Asia/Jakarta"}>+07:00 WIB (Asia/Jakarta)</option>
            <option value={"Asia/Makassar"}>+08:00 WITA (Asia/Makassar)</option>
            <option value={"Asia/Jayapura"}>+09:00 WIT (Asia/Jayapura)</option>
          </Select>
        </div>
        <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.timezone && <>{errors.timezone.message}</>) || (formBackendError.timezone !== "" && <>{formBackendError.timezone}</>)  }</p>
      </div>
      <div>
        <div className="mb-2 block"> <Label htmlFor="phoneNumber" value="Nomor Telepon"/></div>
        <TextInput {...register("phoneNumber")} id="phoneNumber"  value={result?.phoneNumber} onChange={(e)=>{ setResult({...result, phoneNumber: e.target.value }) }} />
        <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.phoneNumber && <>{errors.phoneNumber.message}</>) || (formBackendError.phoneNumber !== "" && <>{formBackendError.phoneNumber}</>)  }</p>
      </div>

      <div>
        <div className="mb-2 block"> <Label htmlFor="faxNumber" value="Nomor Fax"/></div>
        <TextInput {...register("faxNumber")} id="faxNumber"  value={result?.faxNumber} onChange={(e)=>{ setResult({...result, faxNumber: e.target.value }) }} />
        <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.faxNumber && <>{errors.faxNumber.message}</>) || (formBackendError.faxNumber !== "" && <>{formBackendError.faxNumber}</>)  }</p>
      </div>
      <div>
        <div className="mb-2 block"> <Label htmlFor="email" value="Email"/></div>
        <TextInput {...register("email")} id="email"  value={result?.email} onChange={(e)=>{ setResult({...result, email: e.target.value }) }} />
        <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.email && <>{errors.email.message}</>) || (formBackendError.email !== "" && <>{formBackendError.email}</>)  }</p>
      </div>
      <div>
        <div className="mb-2 block"> <Label htmlFor="website" value="Website"/></div>
        <TextInput {...register("website")} id="website"  value={result?.website} onChange={(e)=>{ setResult({...result, website: e.target.value }) }} />
        <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.website && <>{errors.website.message}</>) || (formBackendError.website !== "" && <>{formBackendError.website}</>)  }</p>
      </div>
      <div>
        <Button type="submit" disabled={ updating === true}>
          { updating ? <div className="mr-3"> <Spinner size="sm" light={true} /></div> : null }
          Update
        </Button>
      </div>
    </form>
  </>
};

export default CompanyDetailProfile

export function useFetch(company_id: string) :[Company, React.Dispatch<React.SetStateAction<Company>>, ApiErrorResponse | undefined] {
  const [result, setResult] = useState<Company>({id: company_id, domain: "", name: "", formalName: "", businessField: "", timezone: "", address: "", phoneNumber: "", faxNumber: "", email: "", website: "", logo: "" });
  const [error, setError] = useState<ApiErrorResponse>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    async function fetch(_company_id: string) {
      try {
        dispatch(showLoading())
        var res = await SuperadminServices.getCompanyByCode(_company_id);
        setResult(res.data);
        dispatch(setHeader({ title: "Company Detail - " + res.data.name, hasBack: true }))
      } catch (error) {
        if(error as ApiErrorResponse){
          setError(error as ApiErrorResponse);
          toast.error((error as ApiErrorResponse).message)
        }  else {
          console.log("Unknown error:",error);
          setError(UnknownError(""));
          toast.error("Internal Error")
        }
      } finally {
        dispatch(hideLoading())
      }
    }
    fetch(company_id)
  }, []);

  return [result, setResult, error];
}
