import { Button, Label, Spinner, TextInput } from "flowbite-react";
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { hideLoading, setHeader, showLoading } from "../../redux/global/global.action";
import { useAppDispatch } from "../../redux/hook";
import { CompanyTax, FormState } from "../../_interfaces";
import { ApiErrorResponse, UnknownError } from "../../_network/response";
import { SuperadminServices } from "../../_services";
import * as yup from 'yup'
import { toast } from 'react-toastify';

const CompanyDetailTax = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(
      setHeader({
        title: "Company Detail - Tax",
        hasBack: true,
      })
    );
    dispatch(hideLoading())
  }, []);
  const params = useParams();
  const company_id: string = params.id ? params.id : ""

  // 1. Hook for Getter
  const [result, setResult] = useState<CompanyTax>({taxNo:"", companyId:company_id, taxAddress:"", taxName:"", taxPhoto:""});
  const [error, setError] = useState<ApiErrorResponse>();
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetch(_company_id: string) {
      try {
        dispatch(showLoading())
        var res = await SuperadminServices.getCompanyTaxByCode(_company_id);
        setResult(res.data);
      } catch (error) {
        if(error as ApiErrorResponse){
          setError(error as ApiErrorResponse);
          toast.error((error as ApiErrorResponse).message)
        }  else {
          console.log("Unknown error:",error);
          setError(UnknownError(""));
          toast.error("Internal Error")
        }
      } finally {
        dispatch(hideLoading())
      }
    }

    fetch(company_id)
  }, []);
  useEffect(() => { reset() }, [result]); // to reset value for yup form validator

  // 2. Hook for Updater
  const [updating, setUpdating] = useState(false)
  const [formBackendError, setFormBackendError]   = useState({ taxNo: "", taxName:"", taxAddress:"", taxPhoto: ""})

  const FormCompanyTaxSchema = yup.object().shape({
    taxNo: yup.string(),
    taxName: yup.string(),
    taxAddress: yup.string(),
    taxPhoto: yup.string(),
  })

  const { register, handleSubmit, formState: { errors  }, reset } = useForm<CompanyTax>({ mode: 'onChange', resolver: yupResolver(FormCompanyTaxSchema) });

  const onSubmitHandler = async (data: CompanyTax) => {
    try {
      setUpdating(true)
      setFormBackendError({taxNo: "", taxAddress:"",taxName:"",taxPhoto:""})
      data.companyId = company_id
      await SuperadminServices.updateCompanyTax(data);
      toast.success("Update data sukses")
    } catch (error) {
      if(error as ApiErrorResponse){
        setError(error as ApiErrorResponse);
        (error as ApiErrorResponse).other_errors.forEach( (e) => {
          switch(e.field){
              case "taxNo": setFormBackendError({...formBackendError, taxNo: e.message} ); break;
              case "taxName": setFormBackendError({...formBackendError, taxName: e.message} ); break;
              case "taxAddress": setFormBackendError({...formBackendError, taxAddress: e.message} ); break;
              case "taxPhoto": setFormBackendError({...formBackendError, taxPhoto: e.message} ); break;
          }
        })
        toast.error((error as ApiErrorResponse).message)
      } else {
        console.log("Unknown error:",error);
        setError(UnknownError(""));
        toast.error("Internal Error")
      }
    } finally {
      setUpdating(false)
    }
  };

  // https://flowbite.com/docs/components/tabs/
  return <>
    <ul className="flex mb-4 flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
      <li className="mr-2">
        <Link to={"/company/" + params.id} className="inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"> Profil </Link>
      </li>
      <li className="mr-2">
        <Link to={"/company/" + params.id + "/tax"} className="inline-block p-4 text-blue-600 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"> Data Wajib Pajak </Link>
      </li>
      <li className="mr-2">
        <Link to={"/company/" + params.id + "/period"} className="inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"> Periode </Link>
      </li>
      <li className="mr-2">
        <Link to={"/company/" + params.id + "/ewa"} aria-current="page" className="inline-block p-4 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500"> EWA </Link>
      </li>
      <li className="mr-2">
        <Link to={"/company/" + params.id + "/loan"} aria-current="page" className="inline-block p-4 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500"> Pinjaman </Link>
      </li>
    </ul>

   
    <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmitHandler)}>
      <div>
        <div className="mb-2 block"> <Label htmlFor="taxNo" value="No NPWP"/></div>
        <TextInput {...register("taxNo")} id="taxNo"  value={result?.taxNo} onChange={(e)=>{ setResult({...result, taxNo: e.target.value }) }} />
        <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.taxNo && <>{errors.taxNo.message}</>) || (formBackendError.taxNo != "" && <>{formBackendError.taxNo}</>)  }</p>
      </div>
      <div>
        <div className="mb-2 block"> <Label htmlFor="taxName" value="Nama"/></div>
        <TextInput {...register("taxName")} id="taxName" value={result?.taxName} onChange={(e)=>{ setResult({...result, taxName: e.target.value }) }} />
        <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.taxName && <>{errors.taxName.message}</>) || (formBackendError.taxName != "" && <>{formBackendError.taxName}</>)  }</p>
      </div>
      <div>
        <div className="mb-2 block"> <Label htmlFor="taxAddress" value="Alamat"/></div>
        <TextInput {...register("taxAddress")} id="taxAddress" value={result?.taxAddress} onChange={(e)=>{ setResult({...result, taxAddress: e.target.value }) }} />
        <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.taxAddress && <>{errors.taxAddress.message}</>) || (formBackendError.taxAddress != "" && <>{formBackendError.taxAddress}</>)  }</p>
      </div>
      <div>
        <div className="mb-2 block"> <Label htmlFor="taxPhoto" value="Scan NPWP"/></div>
        <TextInput {...register("taxPhoto")} id="taxPhoto" value={result?.taxPhoto} onChange={(e)=>{ setResult({...result, taxPhoto: e.target.value }) }} />
        <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.taxPhoto && <>{errors.taxPhoto.message}</>) || (formBackendError.taxPhoto != "" && <>{formBackendError.taxPhoto}</>)  }</p>
      </div>
      <div>
        <Button type="submit" disabled={ updating == true}>
          { updating ? <div className="mr-3"> <Spinner size="sm" light={true} /></div> : null }
          Update 
        </Button>
      </div>
    </form>
   
  </>
};

export default CompanyDetailTax;
