export const config: {
  [key: string]: string | undefined;
} = {
  superadmin: process.env.REACT_APP_API_SUPERADMIN,
};

export const endpoint = (key: string): string | undefined => {
  if (process.env.REACT_APP_API_ENABLE_LOCALHOST === 'true') {
    if (config[key]) {
      return config[key];
    }
  }

  return key;
};
