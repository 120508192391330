import { useEffect, useState } from "react";
import { useAppDispatch } from "../../redux/hook";
import { hideLoading, setHeader, showLoading } from "../../redux/global/global.action";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Label, Modal, Pagination, Select, Table, TextInput } from "flowbite-react";
import { ApiErrorResponse, UnknownError } from "../../_network/response";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faPlus } from "@fortawesome/free-solid-svg-icons";
import moment from "moment-timezone";
import { HolidayApiServices } from "../../_services/holiday-api.services";
import { HolidayTemplate, HolidayType } from "../../_interfaces/holiday-api.interfaces";
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from "react-hook-form";
import DatePicker from 'react-datepicker'


const CalendarTemplatePage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(setHeader({ title: "Kalender Kerja (Template)" }));
    dispatch(hideLoading())
  }, []);
  const now = moment.tz(Date.now(), 'Asia/Jakarta')
  const [year, setYear] = useState(now.year())
  const [result, error, mutate] = useFetch(year)
  const [createModal, setCreateModal] = useState(false)
  
  const [updateModal, setUpdateModal] = useState<UpdateReqState>({show: false, data: {id: 0, dateAt: new Date(), holidayType: HolidayType.NATIONAL_HOLIDAY, name:""}});
  const [deleteModal, setDeleteModal] = useState<DeleteReqState>({show: false, id:0});

  return (
    <>
      {/* https://flowbite.com/docs/components/tables/ */}
      <div className="flex items-center justify-center">
        <div className="inline-flex">
          <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-full" onClick={ () => setYear(year - 1) }>
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
          <div className="flex items-center mr-2 ml-2 align-middle text-center">{year}</div>
          <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-full" onClick={() => setYear(year + 1)}>
          <FontAwesomeIcon icon={faArrowRight} />
          </button>
        </div>
      </div>
      <CreateModal 
        show={createModal} 
        year={year}
        onSubmitSuccess={ ()=> {mutate()}} 
        onClose={() => setCreateModal(false)}></CreateModal>
      <UpdateModal state={updateModal} setState={setUpdateModal} onSubmitSuccess={ ()=> {mutate()}} ></UpdateModal>
      <DeleteModal state={deleteModal} setState={setDeleteModal} onSubmitSuccess={ ()=> {mutate()}} ></DeleteModal>

      <Button color="success" size="md" onClick={()=>{ setCreateModal(true) }}><FontAwesomeIcon icon={faPlus} className="mr-2 h-5 w-5" ></FontAwesomeIcon> Tambah </Button>
      <Table striped={true} className={"mt-5"}>
        <Table.Head>
          <Table.HeadCell> No </Table.HeadCell>
          <Table.HeadCell> Tanggal </Table.HeadCell>
          <Table.HeadCell> Nama </Table.HeadCell>
          <Table.HeadCell> Tipe </Table.HeadCell>
          <Table.HeadCell> Action </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {
            (result !== undefined && result.length <= 0)  ?
            <Table.Cell colSpan={4}> Belum ada template untuk tahun ini </Table.Cell>
            :
            result.map( (c, i) => {
              return <Table.Row key={"company-"+c.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {(i+1).toString()}
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap"> {moment(c.dateAt).format('DD-MM-YYYY')} </Table.Cell>
                <Table.Cell className="whitespace-nowrap"> {c.name} </Table.Cell>
                <Table.Cell className="whitespace-nowrap"> {
                  c.holidayType === HolidayType.NATIONAL_HOLIDAY ? 'Libur Nasional' : 
                  c.holidayType === HolidayType.JOINT_LEAVE ? 'Cuti Bersama' : 
                  c.holidayType === HolidayType.COMPANY_EVENT ? 'Event Perusahaan' : 'Lainnya'
                } </Table.Cell>
                <Table.Cell className="flex items-center space-x-3">
                  <Button color="success" size="xs" onClick={()=>{ setUpdateModal({show: true, data: c}) }}>Edit</Button>
                  <Button color="failure" size="xs" onClick={()=>{ setDeleteModal({show: true, id: c.id}) }}>Hapus</Button>
                </Table.Cell>
              </Table.Row>
            })
          }
        </Table.Body>
      </Table>
      
    </>
  );
};

export default CalendarTemplatePage;

function useFetch(year: number) :[HolidayTemplate[], ApiErrorResponse | undefined, ()=>void] {
  const [result, setResult] = useState<HolidayTemplate[]>([]);
  const [error, setError] = useState<ApiErrorResponse>();
  const dispatch = useAppDispatch();
  
  async function mutate() {
    fetch(year)
  }

  async function fetch(_year:number) {
    try {
      dispatch(showLoading())
      var res = await HolidayApiServices.HolidayTemplate_GetByYear(_year);
      setResult(res.data.holidays);
    } catch (error) {
      if(error as ApiErrorResponse){
        setError(error as ApiErrorResponse);
        toast.error((error as ApiErrorResponse).message)
      } else {
        console.log("Unknown error:",error);
        setError(UnknownError(""));
        toast.error("Internal Error")
      }
    } finally {
      dispatch(hideLoading())
    }
  }
  useEffect(() => {
    fetch(year)
  }, [year]);
  
  return [result, error, mutate];
}


type CreateProps = {
  show: boolean
  year: number
  onClose: () => void
  onSubmitSuccess: () => void
}

const CreateModal = (props: CreateProps) => {
  const dispatch = useAppDispatch();
  const [formBackendError, setFormBackendError] = useState({ dateAt  :"", name :"", holidayType :"" })

  const ValidationSchema = yup.object().shape({
      dateAt: yup.date().required(),
      name: yup.string().required(),
      holidayType: yup.string().required().is([HolidayType.NATIONAL_HOLIDAY, HolidayType.JOINT_LEAVE, HolidayType.COMPANY_EVENT, HolidayType.OTHER]),
  })
  const { control, register, handleSubmit, formState: { errors  }, reset, setValue } = useForm<{dateAt: Date; name: string; holidayType: HolidayType; }>({ mode: 'onChange', resolver: yupResolver(ValidationSchema) });

  useEffect(() => {
    if(props.show){ 
      setValue('dateAt', new Date(props.year, 0, 1), { shouldDirty: true })
    }
  }, [props.show]);

  const onSubmitHandler = async (data: {dateAt: Date, name: string, holidayType: HolidayType}) => {
    try {
      dispatch(showLoading())
      setFormBackendError({ dateAt  :"", name :"", holidayType :"" })
      await HolidayApiServices.HolidayTemplate_Create(data.dateAt, data.name, data.holidayType)
      toast.success("Tambah data sukses")
      props.onClose()
      props.onSubmitSuccess()
    } catch (error) {
      if(error as ApiErrorResponse){
        (error as ApiErrorResponse).other_errors.forEach( (e) => {
          switch(e.field){
              case "dateAt" : setFormBackendError({...formBackendError, dateAt: e.message} ); break;
              case "name": setFormBackendError({...formBackendError, name: e.message} ); break;
              case "holidayType": setFormBackendError({...formBackendError, holidayType: e.message} ); break;
         }
        })
        toast.error((error as ApiErrorResponse).message)
      } else {
        console.log("Unknown error:",error);
        toast.error("Internal Error")
      }
    } finally {
      dispatch(hideLoading())
    }
  };
  
  return <>
      <Modal show={props.show} onClose={ ()=> { props.onClose() }} >
          <Modal.Header>Tambah Kalender Kerja</Modal.Header>
          <Modal.Body>
            <form key="create-form" className="flex flex-col gap-4">
              <div>
                <div className="mb-2 block"> <Label htmlFor="dateAt" value="Tanggal"/></div>
                <Controller
                  control={control}
                  name="dateAt"
                  defaultValue={new Date(props.year, 0, 1)}
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    return <DatePicker
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      className="block w-full text-base md:text-sm bg-white border border-gray-300 rounded shadow-sm form-input"
                      dateFormat="dd-MM-yyyy"
                      nextMonthButtonLabel=">"
                      previousMonthButtonLabel="<"
                      selectsStart
                      minDate={new Date(props.year, 0, 1)}
                      maxDate={new Date(props.year, 11, 31)}
                    />
                  }
                }
                />
                
              <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.dateAt && <>{errors.dateAt.message}</>) || (formBackendError.dateAt != "" && <>{formBackendError.dateAt}</>)  }</p>
              </div>
              <div>
                <div className="mb-2 block"> <Label htmlFor="name" value="Nama"/></div>
                <TextInput {...register("name")} id="name"  defaultValue={""} onWheel={(e) => e.currentTarget.blur()} />
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.name && <>{errors.name.message}</>) || (formBackendError.name != "" && <>{formBackendError.name}</>)  }</p>
              </div>
              <div id="select">
                <div className="mb-2 block"> <Label htmlFor="holidayType" value="holidayType (ID)*" /></div>
                <Select  {...register("holidayType")} id="holidayType" required={true} defaultValue={HolidayType.NATIONAL_HOLIDAY}>
                  <option value={HolidayType.NATIONAL_HOLIDAY}> Libur Nasional </option>
                  <option value={HolidayType.JOINT_LEAVE}> Cuti Bersama </option>
                  <option value={HolidayType.COMPANY_EVENT}> Event Perusahaan </option>
                  <option value={HolidayType.OTHER}> Lainnya </option>
                </Select>
              </div>
              <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.holidayType && <>{errors.holidayType.message}</>) || (formBackendError.holidayType != "" && <>{formBackendError.holidayType}</>)  }</p>
      
              <Button onClick={handleSubmit(onSubmitHandler)}>
                Submit
              </Button>
            </form> 
          </Modal.Body>
      </Modal>
  </>
}


export type UpdateReqState = {
  show: boolean
  data: HolidayTemplate
}

type UpdateProps = {
  state: UpdateReqState
  setState: React.Dispatch<React.SetStateAction<UpdateReqState>>
  onSubmitSuccess: () => void
}

const UpdateModal = (props: UpdateProps) => {
  const dispatch = useAppDispatch();
  const [formBackendError, setFormBackendError] = useState({ name :"", holidayType :"" })

  const ValidationSchema = yup.object().shape({
      name: yup.string().required(),
      holidayType: yup.string().required().is([HolidayType.NATIONAL_HOLIDAY, HolidayType.JOINT_LEAVE, HolidayType.COMPANY_EVENT, HolidayType.OTHER]),
  })
  const { control, register, handleSubmit, formState: { errors  }, reset, setValue } = useForm<{name: string; holidayType: HolidayType; }>({ mode: 'onChange', resolver: yupResolver(ValidationSchema) });

  useEffect(() => {
    reset()
  }, [props.state]);
  
  const onSubmitHandler = async (data: {name: string, holidayType: HolidayType}) => {
    try {
      dispatch(showLoading())
      setFormBackendError({  name :"", holidayType :"" })
      await HolidayApiServices.HolidayTemplate_Update(props.state.data.id, data.name, data.holidayType)
      toast.success("Edit data sukses")
      props.setState({...props.state, show: false})
      props.onSubmitSuccess()
    } catch (error) {
      if(error as ApiErrorResponse){
        (error as ApiErrorResponse).other_errors.forEach( (e) => {
          switch(e.field){
              case "name": setFormBackendError({...formBackendError, name: e.message} ); break;
              case "holidayType": setFormBackendError({...formBackendError, holidayType: e.message} ); break;
         }
        })
        toast.error((error as ApiErrorResponse).message)
      } else {
        console.log("Unknown error:",error);
        toast.error("Internal Error")
      }
    } finally {
      dispatch(hideLoading())
    }
  };
  
  return <>
      <Modal show={props.state.show} onClose={ ()=> { props.setState({...props.state, show: false}) }} >
          <Modal.Header>Edit</Modal.Header>
          <Modal.Body>
            <form key="update-form" className="flex flex-col gap-4">
              <div>
                <div className="mb-2 block"> <Label htmlFor="dateAt" value="Tanggal"/></div>
                <TextInput id="dateAt" value={moment(props.state.data.dateAt).format('DD-MM-YYYY')} disabled={true} />
              </div>
              <div>
                <div className="mb-2 block"> <Label htmlFor="name" value="Nama"/></div>
                <TextInput {...register("name")} id="name" value={props.state.data.name} onChange={ (e)=> { props.setState({...props.state, data: {...props.state.data, name: e.target.value} }) }}  onWheel={(e) => e.currentTarget.blur()} />
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.name && <>{errors.name.message}</>) || (formBackendError.name != "" && <>{formBackendError.name}</>)  }</p>
              </div>
              <div id="select">
                <div className="mb-2 block"> <Label htmlFor="holidayType" value="holidayType (ID)*" /></div>
                <Select  {...register("holidayType")} id="holidayType" required={true} value={props.state.data.holidayType} 
                  onChange={ (e)=> { props.setState({...props.state, data: {...props.state.data, 
                    holidayType: 
                      e.target.value === HolidayType.NATIONAL_HOLIDAY ? HolidayType.NATIONAL_HOLIDAY :
                      e.target.value === HolidayType.COMPANY_EVENT ? HolidayType.COMPANY_EVENT :
                      e.target.value === HolidayType.JOINT_LEAVE ? HolidayType.JOINT_LEAVE : HolidayType.OTHER
                  } }) }}
                >
                  <option value={HolidayType.NATIONAL_HOLIDAY}> Libur Nasional </option>
                  <option value={HolidayType.JOINT_LEAVE}> Cuti Bersama </option>
                  <option value={HolidayType.COMPANY_EVENT}> Event Perusahaan </option>
                  <option value={HolidayType.OTHER}> Lainnya </option>
                </Select>
              </div>
              <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.holidayType && <>{errors.holidayType.message}</>) || (formBackendError.holidayType != "" && <>{formBackendError.holidayType}</>)  }</p>
      
              <Button onClick={handleSubmit(onSubmitHandler)}>
                Submit
              </Button>
            </form> 
          </Modal.Body>
      </Modal>
  </>
}


export type DeleteReqState = {
  show: boolean
  id: number
}

type DeleteProps = {
  state: DeleteReqState
  setState: React.Dispatch<React.SetStateAction<DeleteReqState>>
  onSubmitSuccess: () => void
}

const DeleteModal = (props: DeleteProps) => {
  const dispatch = useAppDispatch();
  const [currentid, setCurrentid] = useState<number>(0)

  const onSubmitHandler = async () => {
    try {
      dispatch(showLoading())
      await HolidayApiServices.HolidayTemplate_Delete(props.state.id)
      toast.success("Hapus data sukses")
      props.setState({...props.state, show: false})
      props.onSubmitSuccess()
    } catch (error) {
      if(error as ApiErrorResponse){
        toast.error((error as ApiErrorResponse).message)
      } else {
        console.log("Unknown error:",error);
        toast.error("Internal Error")
      }
    } finally {
      dispatch(hideLoading())
    }
  };
  
  return <>
      <Modal show={props.state.show} onClose={ ()=> { props.setState({...props.state, show: false}) }} >
          <Modal.Header>Apakah anda yakin menghapus data ini?</Modal.Header>
          <Modal.Body>
            <form key="delete-form" className="flex flex-col gap-4">
              <div className="flex flex-wrap gap-2 self-center">
              <Button color={"failure"} onClick={onSubmitHandler}>
                Hapus
              </Button>
              <Button color="light" onClick={()=>{props.setState({...props.state, show: false})}}>
                Batal
              </Button>
              </div>
            </form> 
          </Modal.Body>
      </Modal>
  </>
}
