import { Button, Checkbox, Label, Modal, Select, Spinner, Table, TextInput } from "flowbite-react";
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { hideLoading, setHeader, showLoading } from "../../redux/global/global.action";
import { useAppDispatch } from "../../redux/hook";
import { CompanyEwa, LimitBasedFee, TransactionBasedFee } from "../../_interfaces";
import { ApiErrorResponse, UnknownError } from "../../_network/response";
import { SuperadminServices } from "../../_services";
import * as yup from 'yup'
import { toast } from 'react-toastify';
import { CompanyLoanSetting, UpdateCompanyLoanSettingReq } from "_interfaces/loan.interfaces";
import { LoanSuperAdminApi } from "_services/loan.services";

const CompanyDetailLoan = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(
      setHeader({
        title: "Company Detail - Pinjaman",
        hasBack: true,
      })
    );
    dispatch(hideLoading())
  }, [dispatch]);

  useEffect(() => {
    GetCompanyLoanSetting()
  }, [])

  const [loanData, setLoanData] = useState<CompanyLoanSetting>();

  const params = useParams();
  const [updating, setUpdating] = useState(false);
  const [formBackendError, setFormBackendError] = useState({
    companyId: "",
    adminFeeFixed: "",
    adminFeePercent: "",
    interestType: "",
    interestFeePercent: "",
    limitPercent: "",
  })
  
  const ValidationSchema = yup.object().shape({
    companyId: yup.string().required(),
    adminFeeFixed: yup.number().required(),
    adminFeePercent: yup.number(),
    interestType: yup.string().required(),
    interestFeePercent: yup.number().required(),
    limitPercent: yup.number().required(),
  })

  const { register, handleSubmit, formState: { errors  }, reset, setValue } = useForm<UpdateCompanyLoanSettingReq>({ mode: 'onSubmit', resolver: yupResolver(ValidationSchema) });

  const GetCompanyLoanSetting = async () => {
    try {
      dispatch(showLoading())
      var res = await LoanSuperAdminApi.GetCompanyLoanSetting(params?.id ?? "");
      setLoanData(res.data);
      setValue("companyId", res.data.companyId)
      setValue("adminFeeFixed", res.data.adminFeeFixed)
      setValue("adminFeePercent", res.data.adminFeePercent)
      setValue("interestType", res.data.interestType)
      setValue("interestFeePercent", res.data.interestFeePercent)
      setValue("limitPercent", res.data.limitPercent)
    } catch (error) {
      if(error as ApiErrorResponse){
        toast.error((error as ApiErrorResponse).message)
      }  else {
        console.log("Unknown error:",error);
        toast.error("Internal Error")
      }
    } finally {
      dispatch(hideLoading())
    }
  }

  const onSubmitHandler = async (data: UpdateCompanyLoanSettingReq) => {
    try {
      setUpdating(true)
      setFormBackendError({ 
        companyId: "",
        adminFeeFixed: "",
        adminFeePercent: "",
        interestType: "",
        interestFeePercent: "",
        limitPercent: "",
      })

      await LoanSuperAdminApi.UpdateCompanyLoanSetting(data);

      toast.success("Update data sukses")
    } catch (error) {
      if(error as ApiErrorResponse){
        (error as ApiErrorResponse).other_errors.forEach( (e) => {
          switch(e.field){
            case "companyId": setFormBackendError({...formBackendError, companyId: e.message} ); break;
            case "adminFeeFixed": setFormBackendError({...formBackendError, adminFeeFixed: e.message} ); break;
            case "adminFeePercent": setFormBackendError({...formBackendError, adminFeePercent: e.message} ); break;
            case "interestType": setFormBackendError({...formBackendError, interestType: e.message} ); break;
            case "interestFeePercent": setFormBackendError({...formBackendError, interestFeePercent: e.message} ); break;
            case "limitPercent": setFormBackendError({...formBackendError, limitPercent: e.message} ); break;
          }
        })
        toast.error((error as ApiErrorResponse).message)
      } else {
        console.log("Unknown error:",error);
        toast.error("Internal Error")
      }
    } finally {
      setUpdating(false)
    }
  }

  return <>
    <ul className="flex mb-4 flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
      <li className="mr-2">
        <Link to={"/company/" + params.id} className="inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"> Profil </Link>
      </li>
      <li className="mr-2">
        <Link to={"/company/" + params.id + "/tax"} className="inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"> Data Wajib Pajak </Link>
      </li>
      <li className="mr-2">
        <Link to={"/company/" + params.id + "/period"} className="inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"> Periode </Link>
      </li>
      <li className="mr-2">
        <Link to={"/company/" + params.id + "/ewa"} aria-current="page" className="inline-block p-4 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500"> EWA </Link>
      </li>
      <li className="mr-2">
        <Link to={"/company/" + params.id + "/loan"} aria-current="page" className="inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500"> Pinjaman </Link>
      </li>
    </ul>
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <div>
          <div className="mb-2"><Label>Fixed Admin Fee</Label></div>
          <TextInput type={"number"} {...register("adminFeeFixed")} defaultValue={loanData?.adminFeeFixed}/>
          <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.adminFeeFixed && <>{errors.adminFeeFixed.message}</>) || (formBackendError.adminFeeFixed !== "" && <>{formBackendError.adminFeeFixed}</>)  }</p>
        </div>
        <div>
          <div className="mb-2"><Label>% Admin Fee</Label></div>
          <TextInput type={"number"} {...register("adminFeePercent")} defaultValue={loanData?.adminFeePercent}/>
          <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.adminFeePercent && <>{errors.adminFeePercent.message}</>) || (formBackendError.adminFeePercent !== "" && <>{formBackendError.adminFeePercent}</>)  }</p>
        </div>
        <div>
          <div className="mb-2"><Label>% Bunga (Interest fee)</Label></div>
          <TextInput type={"number"} {...register("interestFeePercent")} defaultValue={loanData?.interestFeePercent}/>
          <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.interestFeePercent && <>{errors.interestFeePercent.message}</>) || (formBackendError.interestFeePercent !== "" && <>{formBackendError.interestFeePercent}</>)  }</p>
        </div>
        <div>
          <div className="mb-2"><Label>Tipe Bunga</Label></div>
          <TextInput {...register("interestType")} defaultValue={loanData?.interestType}/>
          <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.interestType && <>{errors.interestType.message}</>) || (formBackendError.interestType !== "" && <>{formBackendError.interestType}</>)  }</p>
        </div>
        <div>
          <div className="mb-2"><Label>Limit % cicilan dari Gaji</Label></div>
          <TextInput type="number" {...register("limitPercent")} defaultValue={loanData?.limitPercent}/>
          <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.limitPercent && <>{errors.limitPercent.message}</>) || (formBackendError.limitPercent !== "" && <>{formBackendError.limitPercent}</>)  }</p>
        </div>
      </div>
      <div className="mt-2">
        <Button type="submit" color={"info"}>Update</Button>
      </div>
    </form>
  </>
}

export default CompanyDetailLoan