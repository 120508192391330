import { CompanyLoanSetting, UpdateCompanyLoanSettingReq } from "_interfaces/loan.interfaces";
import { ApiSuccessResponse } from "_network/response";
import { LoginPhoneNumberReq } from "../_interfaces";
import request from "../_network/request";

const GetCompanyLoanSetting = (companyId: string): Promise<ApiSuccessResponse<CompanyLoanSetting>> => {
  return request({
    url: `loan/superadmin/company/setting?companyId=${companyId}`,
    method: "GET",
  });
};

const UpdateCompanyLoanSetting = (payload: UpdateCompanyLoanSettingReq): Promise<ApiSuccessResponse<void>> => {
  return request({
    url: `loan/superadmin/company/setting`,
    method: "PUT",
    data: payload
  });
};

export const LoanSuperAdminApi = {
  GetCompanyLoanSetting,
  UpdateCompanyLoanSetting
};
