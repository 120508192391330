import { DigitalProduct, GetDigitalProductRequest, GetDigitalProductResponse, GetListRequest, GetListResponse, UpdateDigitalProductRequest } from "_interfaces/digitalProduct.interface";
import { ApiSuccessResponse } from "_network/response";
import request from "../_network/request";
// const authUrl = "/employee/profile";

const GetDigitalProduct = (params: GetDigitalProductRequest): Promise<ApiSuccessResponse<GetDigitalProductResponse>> => {
  return request({
    url: `transaction/admin/digital-product?page=${params?.page}&size=${params?.size}&type=${params?.type}`,
    method: "GET",
  });
};

const FindDigitalProduct = (code: string): Promise<ApiSuccessResponse<DigitalProduct>> => {
  return request({
    url: `transaction/admin/digital-product/${code}`,
    method: "GET",
  });
};

const UpdateDigitalProduct = (payload: UpdateDigitalProductRequest): Promise<ApiSuccessResponse<void>> => {
  return request({
    url: `transaction/admin/digital-product/${payload?.id}`,
    method: "PUT",
    data: {
      price: payload?.price,
      discount: payload?.discount,
      fee: payload?.fee,
      isActive: payload?.isActive,
      detail: payload?.detail,
      voucherImage: payload?.voucherImage
    }
  });
}

const GetList = (params: GetListRequest): Promise<ApiSuccessResponse<GetListResponse>> => {
  return request({
    url: `transaction/admin/list?page=${params?.page}&size=${params?.size}&dateFrom=${params?.dateFrom}&dateTo=${params?.dateTo}`,
    method: "GET",
  });
};

const DeleteDigitalVoucher = (id: number): Promise<ApiSuccessResponse<void>> => {
  return request({
    url: `transaction/admin/digital-product-value/${id}`,
    method: "DELETE",
  })
}

export const DigitalProductApi = {
  GetDigitalProduct,
  UpdateDigitalProduct,
  GetList,
  FindDigitalProduct,
  DeleteDigitalVoucher,
};
